.site-requirements {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.site-requirements-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}

.site-map-div {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.component-to-display-div-site-req {
  width: 50%;
  display: flex;
  justify-content: center;
}
.site-requirements-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}
