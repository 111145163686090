.accessibility {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.accessibility-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}

.accessibility-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}

.statement-container {
  margin: auto;
  max-width: 900px;
  margin-top: 3rem;
}
