@media only screen and (min-width: 900px) {
  .claims {
    margin: auto;
    margin: 1rem;
  }
  .claims {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .claims-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
  }
  .contact-us-div-claims {
    width: 80vw;
    margin: auto;
    margin-top: 2rem;
  }
  .div-cards {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .card1,
  .card2,
  .card3 {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 270px;
    min-width: 330px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .card1:hover,
  .card2:hover,
  .card3:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .claims-info-div {
    width: 80vw;
    margin: auto;
    margin-top: 3rem;
  }
  .link-claims-card {
    color: black;
    text-decoration: none;
  }

  .bold {
    color: #00408c;
    font-size: 14px;
    text-decoration: none;
  }
  .mobile-navigation {
    position: sticky;
    top: 100px;
    margin: auto;
    left: 0;
    right: 0;
    background-color: white;
  }
  .contact-us-container {
    display: flex;
    justify-content: left;
  }
}
@media only screen and (max-width: 900px) {
  .claims {
    margin: auto;
    margin: 1rem;
  }
  .claims {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .claims-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
  }
  .contact-us-div-claims {
    width: 80vw;
    margin: auto;
    margin-top: 2rem;
  }
  .div-cards {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .card1,
  .card2,
  .card3 {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 290px;
    width: 90%;
    max-width: 300px;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .card1:hover,
  .card2:hover,
  .card3:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .claims-info-div {
    width: 80vw;
    margin: auto;
    margin-top: 3rem;
  }
  .link-claims-card {
    color: black;
    text-decoration: none;
  }

  .bold {
    color: #00408c;
    font-size: 14px;
    text-decoration: none;
  }
  .mobile-navigation {
    position: sticky;
    top: 100px;
    margin: auto;
    left: 0;
    right: 0;
    background-color: white;
  }
}
.claims-accident-ul > li,
.claims-accident-ul > li::marker {
  color: black !important;
  list-style-type: disc;
}
