.get-a-quote-route-component {
  position: fixed;
  height: 100%;
  width: 100%;
  color: rgb(0, 0, 0);
  z-index: 50;
  top: 0;
  padding-top: 2rem;
  background:
        /* top, transparent black, faked with gradient */ linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    /* bottom, image */
      url(https://images.pexels.com/photos/8425044/pexels-photo-8425044.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: 0.7s;

  /* optional, center the image */
}
.get-a-quote-route-component.off {
  transform: scale(1.1);
  transition: 0.7s ease-out;
}

.get-a-quote-route-header {
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 50px;
  padding-top: 10%;
}
.get-a-quote-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
}
.get-a-quote-input-div {
  display: block;
  margin: auto;
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.5);
}
.get-a-quote-button-wrapper {
  margin-top: 1rem;
}
@media only screen and (min-width: 600px) {
  .get-a-quote-previous-quote-shown {
    bottom: 325px !important;
  }
}
