@media only screen and (min-width: 900px) {
  .request-help-open,
  .request-help-hidden {
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 600px;
    /* height: 80%; */
    padding: 1rem;
    border-radius: 10px;
    margin: auto;
    inset: 0;
    z-index: 51;
    overflow-y: auto;
    /* max-height: 520px; */
    transition: 0.3s;
    height: fit-content;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

  .request-help-open {
    opacity: 1;
  }
  .request-help-hidden {
    transform: scale(0.95);
    pointer-events: none;
    opacity: 0;
  }
  .request-help-component-header {
    display: flex;
    justify-content: space-between;
  }
  .request-help-component-inner-header {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }
  .request-help-content {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .request-help-back-button {
    position: absolute;
    left: 25px;
    cursor: pointer;
  }
  .request-help-continue-button-container {
    display: flex;
    justify-content: center;
  }
  .date-picker {
    padding: 1rem;
    border-radius: 5px;
    font-size: 18px;
    max-width: 245px;
    width: 100%;
    border: 1px solid rgb(196, 196, 196);
  }
  .requested-help-buttons {
    margin: 0 10px;
    background-color: #266f00;
    color: white;
    padding: 10px;
    font-size: 18px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  .requested-help-buttons-container {
    text-align: center;
    margin-bottom: 200px;
  }
  .requested-help-container {
    width: 100%;
    text-align: center;

    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 900px) {
  .request-help-open {
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 85%;
    height: fit-content;
    padding: 2rem;
    border-radius: 10px;
    margin: auto;
    inset: 0;
    z-index: 51;
    transition: 0.3s;
    overflow-y: auto;
    font-size: 12px;
    /* max-height: 520px; */
  }
  .request-help-hidden {
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 85%;
    min-height: 100px;
    max-height: 80%;
    padding: 1rem;
    border-radius: 10px;
    margin: auto;
    inset: 0;
    z-index: 51;
    transition: 0.3s;
    opacity: 0;
    transform: scale(0.95);
    pointer-events: none;
    font-size: 12px;
  }
  .request-help-component-header {
    display: flex;
    justify-content: space-between;
  }
  .request-help-component-inner-header {
    display: flex;
    justify-content: center;
  }
  .request-help-component-inner-header h2 {
    margin-top: 2rem;
  }
  .request-help-content {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .request-help-back-button {
    position: absolute;
    left: 25px;
    cursor: pointer;
  }
  .request-help-continue-button-container {
    display: flex;
    justify-content: center;
  }
  .date-picker {
    padding: 1rem;
    border-radius: 5px;
    z-index: 999;
    width: 100%;
    max-width: 245px;
    border: 1px solid rgb(196, 196, 196);
  }
  .lien_holder_requested_help_page_container {
    margin-top: 100px;
  }
  .lien_holder_requested_help_page_container > h1 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .requested-help-buttons {
    margin-top: 10px;
    background-color: #266f00;
    color: white;
    padding: 10px;
    font-size: 18px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  .requested-help-buttons-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 200px;
  }
  .requested-help-container {
    width: 100%;
    text-align: center;
    min-height: 38vh;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: -50px;
  }
}

.how-can-we-help-input {
  width: 100%;
}

.request-help-back-button {
  position: absolute;
  top: 15px;
  left: 15px;
}

.request-help-continue-button,
.verify-me,
.request-help-submit-button {
  background-color: #266f00 !important;
  color: white !important;
}

.request-help-header {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(160, 160, 160);
}

@media only screen and (max-width: 600px) {
  .request-help-parent {
    display: inline-block;
    width: 95%;
    margin: 0;
  }
  .verification-options {
    display: block !important;
  }

  .verification-options-policy {
    margin-bottom: 5px;
  }
  .verification-options-header {
    margin-right: 0% !important;
  }
  .policy-related-container input,
  .claims-related-container input {
    width: 250px;
  }
  .policy-related-inner h3,
  .claims-related-inner h3 {
    display: none;
  }

  .reason-dropdown {
    width: 278px !important;
  }

  .request-help-open {
    max-height: 420px;
  }
}

.MuiButtonBase-root.Mui-disabled {
  background-color: #c0c0c0 !important;
  color: black;
}

.claims-puff-loader {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: 2px solid;
}

.requested-help-bread-crumbs {
  margin-left: 10%;
}

.input-set-width {
  width: 280px !important;
}
