.newUser {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.new-user-iframe {
  display: flex;
  justify-content: center;
  width: 90vw;
  margin: auto;
  margin-top: 2rem;
}
.newUser-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}
.newUser-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}
.create-account-div {
  width: 500px;
  margin-right: 3rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
