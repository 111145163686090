@media only screen and (min-width: 600px) {
  .about-us {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .about-us-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .about-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
  }
  .about-us-info-div {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    margin-top: 3rem;
  }
  .about-us-info {
    max-width: 1200px;
    margin: auto;
    margin-top: 3rem;
  }
  .about-us-column-2 {
    width: 40%;
  }
  .about-us-column-1 {
    width: 55%;
    margin-right: 2rem;
  }
  .about-us-picture {
    width: 85%;
    text-align: center;
  }
  .our-mission-div {
    margin-top: 3rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .about-us {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .about-us-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .about-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .about-us-info-div {
    flex-wrap: wrap;
  }
  .about-us-picture {
    width: 90%;
    margin-top: 2rem;
  }
  .about-us-column-1 {
    margin-top: 2rem;
    margin: 1rem;
  }
  .about-us-column-2 {
    margin: 1rem;
  }
}
