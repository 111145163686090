h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ul-list {
  list-style-type: none;
}
.ul-list-ie li {
  list-style-type: none;
  list-style: none;
}
.blue-color {
  color: rgb(50, 50, 150);
}
.App {
  margin-top: 100px;
}
.darkened-background-on {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 10;
  transition: 200ms;

  /* background-position: center center; */
}
.darkened-background-off {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
  transition: 200ms;
}

.swiper-pagination-bullet {
  background: white !important;
}

hr {
  background-color: rgb(200, 200, 200);
  height: 1px;
  border: 0;
}
@media only screen and (min-width: 600px) {
  .error-message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 25px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
  }
  .error-message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-200px);
    pointer-events: none;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  .message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 25px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
  }
  .message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-80px);
    pointer-events: none;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
}
@media only screen and (max-width: 600px) {
  .error-message-header {
    font-size: 14px;
  }
  .error-message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
    font-size: 14px;
  }
  .error-message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-200px);
    pointer-events: none;
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  .message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
    font-size: 14px;
  }
  .message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-80px);
    pointer-events: none;
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
}

/* Any is gourgeous */
.radio {
  border-radius: 50%;
  border: none;
  outline: none;
}

::marker {
  display: none;
  color: transparent;
}

body {
  padding: 0 !important;
}
