@media only screen and (min-width: 600px) {
  .baa-banner {
    max-width: 700px;
    margin: 56px auto 135px auto;
    padding: 10px 10px 100px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    margin: auto;
    opacity: 1;
    transition: all 500ms;
  }
  .baa-banner-not-rendered {
    max-width: 700px;
    margin: 56px auto 135px auto;
    padding: 10px 10px 100px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    margin: 2rem;
    margin: auto;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .baa {
    font-size: 45px;
    border-bottom-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
  }

  .baa-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .txt-input-div {
    width: 60%;
    margin: auto;
    margin-top: 2rem;
  }
  .txt-input-select {
    padding: 1rem;
    font-size: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}
@media only screen and (max-width: 800px) {
.find-an-agent-div{
  display: inline-block !important;

}

}
@media only screen and (max-width: 600px) {
  .baa-banner {
    max-width: 80%;
    margin: 56px auto 135px auto;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    margin: auto;
    opacity: 1;
    transition: all 500ms;
  }
  .baa-banner-not-rendered {
    max-width: 80%;
    margin: 56px auto 135px auto;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    margin: auto;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .baa {
    font-size: 30px;
    border-bottom-width: 1px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
  }

  .baa-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .txt-input-div {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
  .txt-input-select {
    padding: 1rem;
    font-size: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}
.locations-div{
  min-width: 275px;
  margin:1em;
  line-height: 18px;
}

.faa-img{
  width:250px;}

div.baa-banner.faa-banner > div > span{
  display: flex !important;
  
}

.findButton, .faaCallButton{
  background-color: #01224f;
  border-radius: 5px;
  border: 0;
  color: white;
  font-family: inherit;
  font-size: 1.17em;
  font-weight: bolder;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 17px;
  max-height: 55px;
  cursor: pointer;
  margin-top: 10px;
  /* margin-left: -20px; */
}

.faa-input-div {
    display: flex;
    /* margin-top: -20px !important; */
    width: fit-content;
}

.row1{
  height:30px;
  display: block;
}

.row2{
  height:65px;
  display: block;
}

.row3{
  height:20px;
  display: block;
}

.row4{
  height:fit-content;
  display: block;
}

.top-recommended{
  position: relative;
  left: 64%;
  top: -37px;
  padding: 10px;
  background-color: green;
  color: white;
}
.top-empty{
  position: relative;
  left: 64%;
  top: -37px;
  padding: 10px;
  color: white;
}


.findButton:disabled{
  background-color: grey; cursor: not-allowed;
  background-color: #EBEBE4 !important;
  color: #C6C6C6;
  text-decoration: none;
}

.faa-message{
  top:85px;
}

.no-agents-found{
  margin:1em;
  padding:1em;
  font-weight: bold;
  color:red;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}