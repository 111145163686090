@media only screen and (min-width: 900px) {
  .about-uaic-box-desktop-only {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
    margin-bottom: 25px;
  }
  .about-uaic-box-mobile-only {
    display: none;
  }
  .claimsFaq {
    margin: auto;
    margin: 1rem;
    margin-top: -30px;
    opacity: 1;
    transition: all 500ms;
  }
  .claimsFaq-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .claimsFaq-div {
    width: 80vw;
    margin: auto;
    /* margin-top: 2rem; */
  }
  .info-claims-faq {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .left-link-box {
    border-radius: 5px;
    height: 200px;
    width: 300px;
    padding: 1rem;
    position: sticky;
    top: 100px;
  }
  .left-link-item {
    margin-top: 10px;
    cursor: pointer;
    color: rgb(80, 80, 80);
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }
  .left-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    font-size: 18px;
  }
  .accordion-div {
    width: 50vw;
    margin-left: 1rem;
  }
  .claims-faq-navigation {
    margin-right: 4rem;
    width: 200px;
  }
  .claims-faq-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
    text-decoration: none;
  }
  .claims-faq-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }
  .accordion-list li {
    margin-bottom: 5px;
    list-style: none;
  }
}
@media only screen and (max-width: 900px) {
  .about-uaic-box-desktop-only {
    display: none;
  }
  .about-uaic-box-mobile-only {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .claimsFaq {
    margin: auto;
    margin: 1rem;
    margin-top: 0px;
    left: 0;
    opacity: 1;
    transition: all 500ms;
  }
  .claimsFaq-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .claimsFaq-div {
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 2rem;
  }
  .info-claims-faq {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .left-link-item {
    margin-top: 10px;
    cursor: pointer;
    color: rgb(80, 80, 80);
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }
  .left-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    font-size: 18px;
  }
  .accordion-div {
    width: 100%;
    margin-right: 20px;
    margin-left: -5px;
    margin-top: 40px;
  }
  .claims-faq-navigation {
    margin-right: 4rem;
    width: 200px;
  }
  .claims-faq-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
    text-decoration: none;
  }
  .claims-faq-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }
  .accordion-list li {
    margin-bottom: 5px;
    list-style: none;
  }
  .navigation-mobile-closed-faq {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 25px;
    transition: 0.1s;
    overflow: hidden;
    margin: auto;
    left: 0;
    right: 0;
  }
  .navigation-mobile-open-faq {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 200px;
    transition: 0.3s;
    overflow: hidden;
  }
}
