@media only screen and (min-width: 901px) {
  .videos-carousel-component {
    margin: auto;
    padding: 15px;
    margin-top: 1rem;
    width: calc(100vw - 30px);
    overflow: visible;
    background-color: rgb(9, 32, 63);
    color: white;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .videos-carousel-component > h1 {
    margin-bottom: 10px;
  }

  .videos-mobile-container {
    display: none;
  }
  .videos-desktop-container {
    max-width: 1200px;
    margin: auto;
  }
  .swiper-button-next::after {
    background-color: white;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .swiper-button-prev::after {
    background-color: white;
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
  }
  .video-item-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 300px;
    overflow: hidden;
  }
  .video-and-thumbnail-container {
    position: relative;
    width: 40%;
    height: 0;
    padding-bottom: 56.25%;
    margin-right: 50px;
  }

  .video-and-thumbnail-container > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .video-and-thumbnail-container > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumbnail-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms;
  }
  .thumbnail-overlay:hover {
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    opacity: 1;
    transition: 200ms;
  }
  .thumbnail-overlay-content {
    background-color: rgb(9, 32, 63);
    color: white;
    padding: 10px 30px;
    padding-bottom: 7px;
    border-radius: 5px;
    cursor: pointer;
    transition: 200ms;
    margin-top: 180px;
  }
  .thumbnail-overlay-content > svg {
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  .thumbnail-overlay-content:hover {
    transform: scale(1.05);
    transition: 200ms;
  }
  .video-item-content {
    margin-right: 50px;
    width: calc(50% - 50px);
  }
  .img-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
    pointer-events: none;
  }
  .img-shown-thumbnail {
    opacity: 1;
    transition: 0ms;
  }
  .iframe-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
  }
  .iframe-shown-thumbnail {
    opacity: 1;
    transition: 0ms;
    z-index: 2;
  }
  .video-thumbnail-loading-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 900px) {
  .videos-carousel-component {
    margin: auto;
    padding: 15px;
    width: calc(100vw - 30px);
    overflow: visible;
    color: rgb(0, 0, 0);
    padding-bottom: 10px;
    margin-top: -25px;
  }
  .videos-mobile-container {
    width: 100%;
    position: relative;
  }
  .swiper-component {
    width: 100%;
  }
  .youtube-player {
    width: 100%;
    height: 200px;
  }
  .videos-desktop-container {
    display: none;
  }
  .video-item-container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  .video-and-thumbnail-container {
    margin-top: 30px;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .video-and-thumbnail-container > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .video-and-thumbnail-container > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumbnail-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 1;
    transition: 200ms;
  }

  .thumbnail-overlay-content {
    background-color: rgb(9, 32, 63);
    color: white;
    padding: 10px 30px;
    padding-bottom: 7px;
    border-radius: 5px;
    cursor: pointer;
    transition: 200ms;
    margin-top: 140px;
  }
  .thumbnail-overlay-content:hover {
    transform: scale(1.05);
    transition: 200ms;
  }
  .video-item-content {
    display: none;
  }
  .img-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
    pointer-events: none;
  }
  .img-shown-thumbnail {
    opacity: 1;
    transition: 300ms;
  }
  .iframe-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
  }
  .iframe-shown-thumbnail {
    opacity: 1;
    transition: 300ms;
  }
  .video-thumbnail-loading-container {
    display: none;
  }
}
