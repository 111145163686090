@media only screen and (min-width: 600px) {
  .insurance {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .insurance-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .insurance-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .insurance-info-div {
    margin: auto;
    max-width: 900px;
    margin-top: 3rem;
  }
  .cards-div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 1000px;
    justify-content: left;
    margin-left: -25px;
  }

  .insurance-card-1,
  .insurance-card-2 {
    height: 640px;
    width: 350px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    position: relative;
  }
  .insurance-images {
    width: 100%;
  }
  .insurance-list-item {
    display: flex;
    align-items: center;
    margin: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .insurance {
    margin: auto;
    margin: 1rem;
    transition: all 500ms;
  }
  .insurance-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .insurance-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .insurance-info-div {
    margin: auto;
    margin-top: 3rem;
  }
  .cards-div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    margin-left: -1rem;
  }

  .insurance-card-1,
  .insurance-card-2 {
    height: 640px;
    width: 350px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    position: relative;
  }
  .insurance-images {
    width: 100%;
  }
  .insurance-list-item {
    display: flex;
    align-items: center;
    margin: 1rem;
  }
}
