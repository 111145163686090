@media only screen and (min-width: 600px) {
  .careers {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .careers-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .career-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .career-image {
    width: 600px;
    border-radius: 5px;
  }
  .career-wrapper {
    display: flex;
    width: 80%;
    justify-content: center;
    text-align: left;
  }

  .careers-div {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .component-to-display-div {
    width: 60%;
    display: flex;
    justify-content: center;
  }
  .careers-info-div {
    flex-wrap: wrap;
  }
  .careers-navigation {
    margin-right: 4rem;
    width: 200px;
    padding: 2rem;
    height: 100%;
  }
  .careers-navigation-inner-div {
    position: sticky;
    top: 100px;
  }
  .careers-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
  }
  .careers-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }
}

@media only screen and (max-width: 600px) {
  .careers {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .careers-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .career-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .career-image {
    width: 100%;
    border-radius: 5px;
  }
  .career-wrapper {
    width: 80%;
  }

  .careers-div {
    margin-top: 1rem;
    margin-bottom: 5rem;
    height: 100%;
    width: 100%;
  }
  .component-to-display-div {
    padding-top: 5rem;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
  }
  .careers-info-div {
    flex-wrap: wrap;
  }
  .careers-navigation {
    margin-right: 4rem;
    width: 200px;
  }
  .careers-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
  }
  .careers-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }

  .navigation-mobile-closed {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 25px;
    transition: 0.1s;
    overflow: hidden;
  }
  .navigation-mobile-open {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 380px;
    transition: 0.3s;
    overflow: hidden;
  }
}
