.first-page {
  padding: 1rem;
}
.native-select-required-field {
  font-size: 13px;
  margin-left: 20px;
  margin-top: -10px;
  color: red;
}
.row {
  margin-left: 30px;
}
.first-page-input-div {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .first-page-input {
    padding: 10px;
    border: none;
    background-color: rgb(240, 240, 240);
    font-size: 20px;
    margin-right: 1rem;
    margin-top: 5px;
    border-radius: 5px;
    transition: 0.2s;
    width: 300px;
  }
  .first-page-input:focus {
    outline: none;
    background-color: rgb(215, 215, 215);
    transition: 0.2s;
  }
}
@media only screen and (max-width: 600px) {
  .first-page-input {
    padding: 10px;
    border: none;
    background-color: rgb(240, 240, 240);
    font-size: 20px;
    margin-right: 1rem;
    margin-top: 5px;
    border-radius: 5px;
    transition: 0.2s;
    width: 80%;
  }
  .first-page-input:focus {
    outline: none;
    background-color: rgb(215, 215, 215);
    transition: 0.2s;
  }
}
.otp-component-shown {
  height: 240px;
}
.otp-component-hidden {
  height: 240px;
}
.continue-button-confirm-login-home button {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 1rem;
  transition: all 200ms;
  opacity: 0.65;
  cursor: not-allowed;
}
.invalid-message {
  width: 100%;
}
.continue-button-confirm button {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 1rem;
  transition: all 200ms;
  cursor: not-allowed;
}