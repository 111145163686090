.It {
  opacity: 1;
  transition: 0.2s;
  width: 80%;
}
.It-not-rendered {
  opacity: 0;
  transition: 0.2s;
  transform: translateY(10px);
}
@media only screen and (min-width: 600px) {
  .It {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .It-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .It {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .It-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
