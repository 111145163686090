.footer {
  position: relative;
  background-color: #01224f;
  color: white;
  margin-top: 5rem;
  padding: 1rem;
  background-image: url(https://www.uaig.net/ns/images/footerbg.png);
}
.footer-div {
  display: flex;
  justify-content: space-between;
  color: white;
  width: 80%;
  margin: auto;
}
.footer-first-column {
  display: flex;
}

.footer-links {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.footer-links:hover {
  text-decoration: underline;
}
.logo-and-social-media,
.footer-third-column {
  text-align: center;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
}
.logo-and-social-media-mobile {
  text-align: center;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
}
.footer-logo {
  height: 65px;
}
.icon-footer {
  margin: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.icon-footer:hover {
  color: rgb(199, 199, 199);
  transition: 0.2s;
  transform: translateY(-5px);
}
.footer-image {
  cursor: pointer;
  transition: 0.2s;
}
.footer-image:hover {
  filter: brightness(0.6);
  transition: 0.2s;
}
@media only screen and (max-width: 600px) {
  .footer {
    text-align: center;
    border-radius: 0px;
    background-image: none;
    background-color: rgb(9, 32, 63);
  }
  .geo-trust-div {
    background-image: linear-gradient(rgb(9, 32, 63), rgb(0, 0, 0));
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    padding-bottom: 10px;
  }

}
@media only screen and (min-width: 768px) {
  .footer-mobile{
    display:none !important;
  }
}
@media only screen and (max-width: 767px) {
  .footer-desktop{
    display:none !important;
  }
}
@media only screen and (min-width: 600px) {
  .geo-trust-div {
    background-image: linear-gradient(rgb(0, 34, 80), rgb(0, 0, 0));
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    padding-bottom: 10px;
  }
 
}
