.ribbon {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  width: 345px;
  height: 400px;
  text-align: right;
  pointer-events: none;
}
.ribbon span {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 325px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#2989d8 0%, #1e5799 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
  position: absolute;
  top: 73px;
  left: -65px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 8px solid #1e5799;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #1e5799;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 8px solid transparent;
  border-right: 8px solid #1e5799;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #1e5799;
}
.app-icons {
  margin-top: 10px;
  display: inline-block;
}
.app-icons-download {
  margin: 2px;
}
@media only screen and (min-width: 500px) {
  #MOBILE-ONLY {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .banner-payment-options {
    margin-top: 12px;
    margin-bottom:2%;
  }
  .ribbon {
    left: calc(8% - 50px);
    top: 85px;
  }
  .home-page {
    margin-top: -30px;
    opacity: 1;
    transition: 0.4s;
  }
  .home-page-not-rendered {
    margin-top: -30px;
    opacity: 0;
    transition: 0.4s;
  }
  .home-page-home-text {
    font-size: 50px;
    padding-bottom: 10px;
  }
  .home-picture {
    background-color: #537895;
    background-image: linear-gradient(360deg, #5e7a86 0%, #b9ccd2 74%);
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    height: 450px;
    width: calc(100% - 100px);
    padding: 50px;
    padding-top: 70px;
    margin-top: -100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .waves-background {
    background-color: #5e7a86;
  }
  /* .home-page-left-div {
    width: 55%;
    text-align: center;
    margin-left: 50px;
  } */
  .zip-input {
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    width: 170px;
  }
  .start-quote-button {
    padding: 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    width: 200px;
    transition: 0.2s;
    background-color: #266f00;
    color: black;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s;

    border: 3px solid #4c7a94;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    width: 200px;
    background-color: rgba(255, 255, 225, 0.5);
    font-weight: bold;
  }
  .start-quote-button:hover {
    background-color: rgb(199, 199, 199);
    transition: 0.2s;
  }
  .slide-show {
    display: block;
    z-index: 3;
    width: 100%;
    text-align: center;
    max-width: 1300px;
  }

  .carousel .slide img {
    max-width: 650px !important;
    height: 375px;
  }

  .app-icons {
    margin-top: 10px;
    display: block;
  }
  .app-icons-download {
    height: 40px;
  }
  .grid-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 2rem;
  }
  .grid-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 250px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    align-items: centerS;
  }
  .grid-item:hover {
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    transform: translateY(-3px);
  }

  .home-page-about {
    width: 80%;
    margin: auto;
  }

  .home-page-become-an-agent {
    width: 100%;
    height: 350px;
    background-color: rgb(240, 240, 240);
    margin-bottom: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-page-become-an-agent-picture {
    height: 200px;
    margin: 3rem;
    border-radius: 5px;
  }
  .select-state-select {
    padding: 1rem;
    margin: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
  }
  .select-state-select-card {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
  }
  .grid-item-picture {
    width: 85%;
    min-height: 165px;
    max-height: 165px;
    object-fit: contain;
  }

  .home-page-card-button,
  .home-page-button {
    width: 150px;
    height: 50px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #266f00;
    padding: 10px;
    transition: 0.1s;
    cursor: pointer;
    text-align: center;
  }
  .home-page-card-button:hover,
  .home-page-button:hover {
    transition: 0.1s;
    filter: brightness(1.1);
  }
  .home-page-card-button-off,
  .home-page-button-off {
    width: 150px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #266f00;
    padding: 10px;
    transition: 0.2s;
    opacity: 0.65;
    cursor: not-allowed;
    height: 50px;
  }

  .continue-previous-quote-component-shown {
    position: fixed;
    max-height: 340px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 450px;
    transition: 0.3s;
    z-index: 20;
  }
  .continue-previous-quote-component-hidden {
    position: fixed;
    max-height: 360px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 450px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    transform: scale(0.9);
  }

  .continue-button-confirm-login-home {
    position: relative;
    margin-top: 3rem;

    display: flex;
    justify-content: end;
  }
  .continue-button-confirm-login-home button {
    position: absolute;
    border: none;
    background-color: #09203f;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    width: 150px;
    font-size: 16px;
  }
  .continue-button-confirm-login-home button:hover {
    border: none;
    background-color: #144686;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
  .video-carousel-container > h2 {
    display: none;
  }

  /* waves */
}
/* @media only screen and (min-width: 850px) {
  /* .carousel .slide img {
    max-width: 1400px !important;
    min-width: 800px !important;
  } */
/* .home-picture {
    padding-top: 75px;
    padding-bottom: 0px;
  } */

@media screen and (max-width: 1300px) and (min-width: 600px) {
  .ribbon {
    left: calc(8% - 75px);
    top: 85px;
  }
  .grid-container {
    width: 100%;
  }
  .grid-item {
    width: 215px;
  }
}@media screen and (max-width: 900px) and (min-width: 810px) {
  .find-an-agent-div{
    display:inline-block !important;
  }
}
@media screen and (min-width: 1301px) {
  .ribbon {
    left: 8%;
    top: 85px;
  }
  .grid-container {
    width: 90%;
    max-width: 1200px;
  }
  .grid-item {
    width: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .home-page-left-div {
    margin-bottom: -50px !important;
}
}
@media only screen and (max-width: 600px) {

.find-an-agent-div{
  display:inline-flex !important;
}

  .find-an-agent-header{
   font-size: 24px !important;
  }

  #root > div > main > div > div.grid-container > div:nth-child(6) {
    display: none;
  }

  #root > div > main > div > div.grid-container > a:nth-child(6) {
    display: none;
  }

  .ribbon span {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 224px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#2989d8, #1e5799);
    box-shadow: 0 3px 10px -5px rgb(0 0 0);
    position: absolute;
    top: 37px;
    left: -41px;
  }
  #root
    > div
    > div.home-page
    > div.home-picture
    > div.home-page-left-div
    > div:nth-child(3) {
    margin-top: -30px;
  }

  .ribbon {
    left: calc(7% - 12px);
    top: 70px;
  }
  .third-column {
    text-align: center;
  }
  #root > div > div:nth-child(5) > div > div > div > div.third-column > div {
    display: block !important;
  }

  .grid-item > h2.blue-color,
  .grid-item > div > h2.blue-color {
    text-align: left;
  }
  .Agents-Div > h2.blue-color,
  .Customers-Div > h2.blue-color {
    text-align: center;
  }

  .grid-item > h2.blue-color:after,
  .grid-item > div > h2.blue-color:after,
  .grid-item > .make_a_payment > h2.blue-color:after,
  .grid-item > .make_a_payment > div > h2.blue-color:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-right: 5px solid #003a93;
    border-top: 5px solid #003a93;
    transform: rotate(45deg);
    margin-right: 0.5em;
    float: right;
    margin-top: 10px;
    font-weight: bold;
    color: #003a93;
    text-align: left !important;
  }

  .home-page {
    margin-top: -30px;
    opacity: 1;
    transition: 0.3s;
  }
  .home-page-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0.97);
  }
  .home-page-home-text {
    font-size: 40px;
    padding-bottom: 10px;
  }
  .home-picture {
    background-color: #537895;
    background-image: linear-gradient(360deg, #5e7a86 0%, #b9ccd2 74%);
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    height: 100%;
    margin-top: -100px;
    width: calc(100vw - 40px);
    padding: 20px;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .card-text {
    width: 0px;
    opacity: 0;
  }
  .zip-input {
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    width: 170px;
  }
  .start-quote-button {
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s;
    color: black;
    border: 3px solid #4c7a94;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    width: 200px;
    background-color: rgba(255, 255, 225, 0.5);
    font-weight: bold;
  }

  .waves-background {
    background-color: #5e7a86;
  }

  .slide-show {
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-top: 55px;
  }

  .slide-container {
    float: right;
    width: 100%;
  }
  .grid-container {
    width: 95%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 10px;
  }
  .grid-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 30px;
    width: 80%;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    margin: 10px;
    padding: 1rem;
  }
  .grid-item:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .about-us-column-2 > li {
    text-align: left;
  }
  .home-page-about {
    width: 95%;
    margin: auto;
    text-align: justify;
  }
  .home-page-become-an-agent {
    width: calc(100vw - 40px);
    height: 100%;
    background-color: rgb(240, 240, 240);

    margin-bottom: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;

    text-align: center;
  }
  .home-page-become-an-agent-picture {
    width: 95%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .select-state-select {
    padding: 1rem;
    margin: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
  }
  .select-state-select-card {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
  }
  .grid-item-picture {
    width: 0px;
    pointer-events: none;
    opacity: 0;
  }
  .home-page-card-button {
    width: 0px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    transition: 0.2s;
    opacity: 0;
    pointer-events: none;
  }

  .app-icons {
    margin-top: 10px;
    display: inline-block;
  }
  .app-icons-download {
    height: 40px;
  }
  .continue-previous-quote-component-shown {
    position: fixed;
    height: 60%;
    max-height: 340px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 85%;
    transition: 0.3s;
    z-index: 20;
  }
  .continue-previous-quote-component-hidden {
    position: fixed;
    height: 60%;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 85%;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    transform: scale(0.9);
  }

  .continue-button-confirm-login-home {
    position: absolute;
    bottom: -30px;
    display: flex;
    justify-content: center;
    left: 30%;
  }
  /* .continue-button-confirm-login-home button {
    position: absolute;
    border: none;
    background-color: #09203f;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    width: 75%;
    max-width: 175px;
    font-size: 16px;
  } */
  .about-us-picture {
    width: 100%;
    text-align: center;
  }

  .about-us-info {
    width: 90vw;
    margin: auto;
    margin-top: 3rem;
  }
  .our-mission-div {
    width: 80vw;
    margin: auto;
    margin-top: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
  }
  .video-carousel-container {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .video-carousel-container > h2 {
    padding-left: 15px;
  }
  /* waves */
}

.continue-previous-quote-dark-background-on {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 20;
  transition: 0.3s;
}
.continue-previous-quote-dark-background-off {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 1vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 10vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 30s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 14s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 17s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 20s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 27s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/

.mobileOnly {
  display: none;
}
.desktopOnly {
  display: inline-block;
}

@media (max-width: 768px) {
  .mobileOnly {
    display: inline-block;
    padding-right: 5px;
  }
  .desktopOnly {
    display: none;
  }
  .waves {
    height: 40px;
    min-height: 70px !important;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
  #STRETCH-ME {
    max-width: 650px !important;
    list-style: none;
  }
}

.get-a-quote-component-shown,
.otp-component-shown {
  position: fixed;
  z-index: 23;
  width: 300px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 10px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  text-align: center;
  transition: 0.3s;
}
.get-a-quote-component-hidden,
.otp-component-hidden {
  position: fixed;
  z-index: 0;
  width: 300px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 10px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  text-align: center;
  transform: scale(0.9);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
}

.grid-container {
  max-width: 1200px;
}

#MOBILE-ONLY > a > h2 {
  text-align: left !important;
}

.start-quote-button-container {
  width: fit-content;
  margin: 0 auto;
}

.banner-payment-options {
  font-size: 15px;
  cursor: pointer;
}

.home-page-left-div {
  text-align: center;
}

/* @media screen and (max-width: 800px) {
  .home-picture {
    display: inline-table !important;
  }
}
@media screen and (min-width: 600px) {
  .home-page-left-div {
    width: 100% !important;
    text-align: center;
  }
} */

@media screen and (min-width: 750px) {
  .mobileOnly {
    display: none;
  }
}
@media screen and (max-width: 750px) and (min-width: 525px) {
  .home-picture {
    height: 718px !important;
    display: grid !important;
  }
  .home-picture-div {
    display: block !important;
  }

  .renew-policy {
    display: block;
  }
}

.home-page-left-div {
  width: 100%;
}

.claims-button{
  margin-bottom: 6%;
}