@media only screen and (min-width: 600px) {
  .Not-Found-Page {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 1;
    transition: all 500ms ease-out;
  }
  .Not-Found-Page-not-rendered {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: all 500ms ease-in;
    transform: scale(0.9);
  }
  .Not-Found-Page-Div {
    width: 50%;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 600px) {
  .Not-Found-Page {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 1;
    transition: all 500ms;
  }
  .Not-Found-Page-not-rendered {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: all 500ms;
    transform: scale(0.97);
  }
  .Not-Found-Page-Div {
    margin-top: 2rem;
    width: 90%;
  }
}
