@import url(//fonts.googleapis.com/css?family=Lato:300:400);
@import url(//fonts.googleapis.com/css?family=Lato:300:400);
:root {
  --blue: #01224f;
  --yellow: #ffdd00;
  --red: #a30000;
  --green: #266f00;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
Chrome,
Safari,
Edge,
Opera input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

li {
  list-style: circle;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ul-list {
  list-style-type: none;
}
.ul-list-ie li {
  list-style-type: none;
  list-style: none;
}
.blue-color {
  color: rgb(50, 50, 150);
}
.App {
  margin-top: 100px;
}
.darkened-background-on {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 10;
  transition: 200ms;

  /* background-position: center center; */
}
.darkened-background-off {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
  transition: 200ms;
}

.swiper-pagination-bullet {
  background: white !important;
}

hr {
  background-color: rgb(200, 200, 200);
  height: 1px;
  border: 0;
}
@media only screen and (min-width: 600px) {
  .error-message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 25px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
  }
  .error-message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-200px);
    pointer-events: none;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  .message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 25px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
  }
  .message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 600px;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-80px);
    pointer-events: none;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
}
@media only screen and (max-width: 600px) {
  .error-message-header {
    font-size: 14px;
  }
  .error-message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
    font-size: 14px;
  }
  .error-message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-200px);
    pointer-events: none;
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  .message-app-shown {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    z-index: 100;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    opacity: 1;
    font-size: 14px;
  }
  .message-app-hidden {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0px;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 80%;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    transition: 0.3s;
    transform: translateY(-80px);
    pointer-events: none;
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
}

/* Any is gourgeous */
.radio {
  border-radius: 50%;
  border: none;
  outline: none;
}

::marker {
  display: none;
  color: transparent;
}

body {
  padding: 0 !important;
}

@media only screen and (min-width: 900px) {
  .claims {
    margin: auto;
    margin: 1rem;
  }
  .claims {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .claims-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
  }
  .contact-us-div-claims {
    width: 80vw;
    margin: auto;
    margin-top: 2rem;
  }
  .div-cards {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .card1,
  .card2,
  .card3 {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 270px;
    min-width: 330px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .card1:hover,
  .card2:hover,
  .card3:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .claims-info-div {
    width: 80vw;
    margin: auto;
    margin-top: 3rem;
  }
  .link-claims-card {
    color: black;
    text-decoration: none;
  }

  .bold {
    color: #00408c;
    font-size: 14px;
    text-decoration: none;
  }
  .mobile-navigation {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    margin: auto;
    left: 0;
    right: 0;
    background-color: white;
  }
  .contact-us-container {
    display: flex;
    justify-content: left;
  }
}
@media only screen and (max-width: 900px) {
  .claims {
    margin: auto;
    margin: 1rem;
  }
  .claims {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .claims-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
  }
  .contact-us-div-claims {
    width: 80vw;
    margin: auto;
    margin-top: 2rem;
  }
  .div-cards {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .card1,
  .card2,
  .card3 {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 290px;
    width: 90%;
    max-width: 300px;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
  }
  .card1:hover,
  .card2:hover,
  .card3:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .claims-info-div {
    width: 80vw;
    margin: auto;
    margin-top: 3rem;
  }
  .link-claims-card {
    color: black;
    text-decoration: none;
  }

  .bold {
    color: #00408c;
    font-size: 14px;
    text-decoration: none;
  }
  .mobile-navigation {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    margin: auto;
    left: 0;
    right: 0;
    background-color: white;
  }
}
.claims-accident-ul > li,
.claims-accident-ul > li::marker {
  color: black !important;
  list-style-type: disc;
}

@media only screen and (min-width: 900px) {
  .contact-us-state {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .contact-us-state-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }

  .contact {
    margin: auto;
    opacity: 1;
    margin: 1rem;
    margin-top: 0;
    transition: all 500ms;
  }
  .contact-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .contact-us-container {
    display: flex;
    margin-left: 10vw;
    width: 80%;

    margin-top: 3rem;
  }
  .state-div {
    display: flex;
    flex-wrap: wrap;
  }
  .request-help-parent {
    display: flex;
    justify-content: left;
    width: 80%;
    margin-left: 5%;
    margin-top: 2rem;
  }
  .request-help-about {
    font-size: 18px;
  }
  .request-help-clickable-header:hover {
    text-decoration: underline;
    transition: 0.2s;
    cursor: pointer;
  }
}
@media only screen and (max-width: 500px) {
  .need-assistance {
    margin-bottom: -25px !important;
    float: none !important;
    width: 90% !important;
  }
}
@media only screen and (max-width: 900px) {
  .need-assistance {
    margin-right: 0% !important;
    position: relative !important;
  }
  .contact-us-state {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .contact-us-state-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }

  .contact {
    margin: auto;
    opacity: 1;
    margin: 1rem;
    margin-top: 0;
    transition: 0.3s;
    margin-bottom: 2rem;
  }
  .contact h4 {
    font-weight: bold;
    color: rgb(49, 49, 49);
  }
  .contact-not-rendered {
    margin: auto;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0.97);
  }
  .contact-us-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 3rem;
  }
  .state-div {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: 4rem;
  }
  .state-div div {
    width: 100%;
    margin: auto;
    margin-bottom: 1rem;
  }
  .navigation-mobile-closed-contact-us {
    position: absolute;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 25px;
    transition: 0.1s;
    overflow: hidden;
    z-index: 1;
  }
  .navigation-mobile-open-contact-us {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 140px;
    transition: 0.3s;
    overflow: hidden;
    z-index: 10;
  }
  .request-help-parent {
    display: flex;
    justify-content: left;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
  .request-help-about {
    font-size: 18px;
  }
  .request-help-clickable-header:hover {
    text-decoration: underline;
    transition: 0.2s;
    cursor: pointer;
  }
}
.request-help-clickable-header {
  text-decoration: underline;
}

.MuiInputBase-input,
.MuiSelect-selectMenu {
  width: 175px;
}

.datepicker {
  max-width: 190px;
}

.need-assistance {
  float: right;
  position: absolute;
  margin-right: 6%;
  right: 0;
  margin-top: -25px !important;
}

.contact li {
  line-height: 25px !important;
}
@media only screen and (min-width: 900px) {
  .about-uaic-box-desktop-only {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
    margin-bottom: 25px;
  }
  .about-uaic-box-mobile-only {
    display: none;
  }
  .claimsFaq {
    margin: auto;
    margin: 1rem;
    margin-top: -30px;
    opacity: 1;
    transition: all 500ms;
  }
  .claimsFaq-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .claimsFaq-div {
    width: 80vw;
    margin: auto;
    /* margin-top: 2rem; */
  }
  .info-claims-faq {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .left-link-box {
    border-radius: 5px;
    height: 200px;
    width: 300px;
    padding: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
  }
  .left-link-item {
    margin-top: 10px;
    cursor: pointer;
    color: rgb(80, 80, 80);
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }
  .left-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    font-size: 18px;
  }
  .accordion-div {
    width: 50vw;
    margin-left: 1rem;
  }
  .claims-faq-navigation {
    margin-right: 4rem;
    width: 200px;
  }
  .claims-faq-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
    text-decoration: none;
  }
  .claims-faq-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }
  .accordion-list li {
    margin-bottom: 5px;
    list-style: none;
  }
}
@media only screen and (max-width: 900px) {
  .about-uaic-box-desktop-only {
    display: none;
  }
  .about-uaic-box-mobile-only {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .claimsFaq {
    margin: auto;
    margin: 1rem;
    margin-top: 0px;
    left: 0;
    opacity: 1;
    transition: all 500ms;
  }
  .claimsFaq-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .claimsFaq-div {
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 2rem;
  }
  .info-claims-faq {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .left-link-item {
    margin-top: 10px;
    cursor: pointer;
    color: rgb(80, 80, 80);
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }
  .left-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    font-size: 18px;
  }
  .accordion-div {
    width: 100%;
    margin-right: 20px;
    margin-left: -5px;
    margin-top: 40px;
  }
  .claims-faq-navigation {
    margin-right: 4rem;
    width: 200px;
  }
  .claims-faq-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
    text-decoration: none;
  }
  .claims-faq-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }
  .accordion-list li {
    margin-bottom: 5px;
    list-style: none;
  }
  .navigation-mobile-closed-faq {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 25px;
    transition: 0.1s;
    overflow: hidden;
    margin: auto;
    left: 0;
    right: 0;
  }
  .navigation-mobile-open-faq {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 200px;
    transition: 0.3s;
    overflow: hidden;
  }
}

@media only screen and (min-width: 901px) {
  .videos-carousel-component {
    margin: auto;
    padding: 15px;
    margin-top: 1rem;
    width: calc(100vw - 30px);
    overflow: visible;
    background-color: rgb(9, 32, 63);
    color: white;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .videos-carousel-component > h1 {
    margin-bottom: 10px;
  }

  .videos-mobile-container {
    display: none;
  }
  .videos-desktop-container {
    max-width: 1200px;
    margin: auto;
  }
  .swiper-button-next::after {
    background-color: white;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .swiper-button-prev::after {
    background-color: white;
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
  }
  .video-item-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 300px;
    overflow: hidden;
  }
  .video-and-thumbnail-container {
    position: relative;
    width: 40%;
    height: 0;
    padding-bottom: 56.25%;
    margin-right: 50px;
  }

  .video-and-thumbnail-container > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .video-and-thumbnail-container > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumbnail-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms;
  }
  .thumbnail-overlay:hover {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    opacity: 1;
    transition: 200ms;
  }
  .thumbnail-overlay-content {
    background-color: rgb(9, 32, 63);
    color: white;
    padding: 10px 30px;
    padding-bottom: 7px;
    border-radius: 5px;
    cursor: pointer;
    transition: 200ms;
    margin-top: 180px;
  }
  .thumbnail-overlay-content > svg {
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  .thumbnail-overlay-content:hover {
    transform: scale(1.05);
    transition: 200ms;
  }
  .video-item-content {
    margin-right: 50px;
    width: calc(50% - 50px);
  }
  .img-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
    pointer-events: none;
  }
  .img-shown-thumbnail {
    opacity: 1;
    transition: 0ms;
  }
  .iframe-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
  }
  .iframe-shown-thumbnail {
    opacity: 1;
    transition: 0ms;
    z-index: 2;
  }
  .video-thumbnail-loading-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 900px) {
  .videos-carousel-component {
    margin: auto;
    padding: 15px;
    width: calc(100vw - 30px);
    overflow: visible;
    color: rgb(0, 0, 0);
    padding-bottom: 10px;
    margin-top: -25px;
  }
  .videos-mobile-container {
    width: 100%;
    position: relative;
  }
  .swiper-component {
    width: 100%;
  }
  .youtube-player {
    width: 100%;
    height: 200px;
  }
  .videos-desktop-container {
    display: none;
  }
  .video-item-container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  .video-and-thumbnail-container {
    margin-top: 30px;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .video-and-thumbnail-container > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .video-and-thumbnail-container > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumbnail-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 1;
    transition: 200ms;
  }

  .thumbnail-overlay-content {
    background-color: rgb(9, 32, 63);
    color: white;
    padding: 10px 30px;
    padding-bottom: 7px;
    border-radius: 5px;
    cursor: pointer;
    transition: 200ms;
    margin-top: 140px;
  }
  .thumbnail-overlay-content:hover {
    transform: scale(1.05);
    transition: 200ms;
  }
  .video-item-content {
    display: none;
  }
  .img-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
    pointer-events: none;
  }
  .img-shown-thumbnail {
    opacity: 1;
    transition: 300ms;
  }
  .iframe-hidden-thumbnail {
    opacity: 0;
    transition: 300ms;
  }
  .iframe-shown-thumbnail {
    opacity: 1;
    transition: 300ms;
  }
  .video-thumbnail-loading-container {
    display: none;
  }
}

.header {
  background-color: #09203f;
  margin: 0;
  padding: 10px;
  position: fixed;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: space-between;
}
.header-mobile-closed {
  background-color: #01224f;
  margin: 0;
  padding: 10px;
  position: fixed;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 60px;
  transition: 100ms;
  z-index: 5;
}
.header-mobile-open {
  background-color: #01224f;
  margin: 0;
  padding: 10px;
  position: fixed;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100%;
  transition: 300ms;
  z-index: 10;
}

.navigation-links-div-mobile {
  margin: auto;
  width: 80vw;
}

.login-button {
  margin-right: 30px;
}

.login-button button {
  background-color: #01224f;
  border-radius: 5px;
  border: 0;
  color: white;
  font-family: inherit;
  font-size: 1.17em;
  font-weight: bolder;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 13px;
  cursor: pointer;
}

.login-button button:hover {
  transition: 100ms;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: gray;
  padding: 13px;
}
.navigation-button-aboutUs-active,
.navigation-button-claims-active,
.navigation-button-become-agent-active,
.navigation-button-insurance-active,
.navigation-button-career-active,
.navigation-button-contact-active,
.navigation-button-leinholder-active,
.navigation-button-find-an-agent-active,
.navigation-button-login-active {
  color: white;
  text-decoration: none;
  margin: 0rem 10px 0rem 10px;
  padding: 0rem 5px 0rem 5px;
  transition: 100ms;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: 3px solid white;
  height: 30px;
  font-weight: normal;
}
.navigation-button-hidden {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}
.navigation-button-aboutUs-off,
.navigation-button-aboutUs-off,
.navigation-button-claims-off,
.navigation-button-become-agent-off,
.navigation-button-insurance-off,
.navigation-button-career-off,
.navigation-button-contact-off,
.navigation-button-find-an-agent-off,
.navigation-button-leinholder-off,
.navigation-button-login-off {
  color: white;
  text-decoration: none;
  margin: 0rem 10px 0rem 10px;
  padding: 0rem 5px 0rem 5px;
  transition: 100ms;
  text-align: center;
  display: flex;
  align-items: center;
  height: 30px;
  font-weight: normal;
}

.navigation-links {
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.navigation-button-aboutUs-off:hover,
.navigation-button-claims-off:hover,
.navigation-button-become-agent-off:hover,
.navigation-button-insurance-off:hover,
.navigation-button-career-off:hover,
.navigation-button-contact-off:hover,
.navigation-button-find-an-agent-off:hover,
.navigation-button-leinholder-off:hover,
.navigation-button-login-off:hover {
  border-bottom: 3px solid white;
  height: 30px;
}
.logo-header {
  height: 60px;
  margin: 0;
}

.login-box-opened {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 400px;
  min-width: 340px;
  height: 595px;
  margin: auto;
  transition: all 200ms;
  padding: 1rem;
  border-radius: 10px;
  display: list-item;
  /* overflow: hidden; */
}

.login-box-closed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 700px;
  height: 500px;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  transition: all 200ms;
  transform: scale(0.9);
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.login-box-opened-small {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  height: 500px;
  margin: auto;
  transition: 200ms;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}
/* 
width: 100%;
  max-width: 650px;
  max-height: 500px;
  height: 100%; */

.login-box-closed-small {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  height: 500px;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  transform: scale(0.9);
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.Agents-Div {
  float: left;
  padding: 30px;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  text-align: center;
}

.agent-icon {
  width: auto;
  height: 175px;
}

.Customers-Div {
  float: left;
  padding: 30px;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  text-align: center;
}

.customer-icon {
  width: auto;
  height: 175px;
}

.close-login-menu-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  padding: 10px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 200ms;
}
.close-login-menu-icon:hover {
  padding: 10px;
  box-shadow: 0px 0px 0px 5px rgb(201, 201, 201);
  background-color: rgb(201, 201, 201);
  transition: 200ms;
}

.home-page-card-button,
.home-page-button {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 10px;
  transition: 200ms ease-in;
  cursor: pointer;
}
.home-page-card-button:hover,
.home-page-button:hover {
  transition: 200ms ease-in-out;
  background-color: #266f00;
}
.home-page-card-button-off,
.home-page-button-off {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 10px;
  transition: all 200ms;
  opacity: 0.65;
  cursor: not-allowed;
}

.error-message-shown {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #a30000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: 300ms;
  opacity: 1;
  z-index: 100;
}
.error-message-hidden {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #a30000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: 300ms;
  transform: translateY(-200px);
  opacity: 0;
  color: rgb(0, 0, 0, 0);
  background-color: rgb(0, 0, 0, 0);
}
.message-shown {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #266f00;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 70%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: all 300ms ease-in-out;
  opacity: 1;
  z-index: 100;
}
.message-hidden {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #266f00;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 70%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: 300ms ease-in-out;
  transform: translateY(-200px);
  opacity: 0;
}
.new-user,
.forgot-password {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.new-user:hover,
.forgot-password:hover {
  text-decoration: underline;
  color: #01224f;
}
.forgot-password-component-shown {
  position: absolute;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 80%;
  height: 225px;
  z-index: 21;
  border-radius: 10px;
  transition: all 200ms;
}
.forgot-password-component-hidden {
  position: absolute;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 80%;
  height: 190px;
  z-index: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: 10px;
  transform: scale(0.9);
  transition: all 200ms;
  opacity: 0;
  display: none;
}

.forgot-password-background-on {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 20;
  transition: all 300ms;
  will-change: opacity;
}
.forgot-password-background-off {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms;
  will-change: opacity;
}
.confirm-login-message-component-open {
  /* width: 650px; */
  max-width: 500px;
  width: 90%;
  position: fixed;
  background-color: white;
  margin: auto;
  left: 0;
  right: 0;
  height: 300px;
  top: 0;
  bottom: 0;
  padding: 1rem;
  border-radius: 10px;
  z-index: 21;
  transition: all 300ms;
}
.confirm-login-message-component-closed {
  max-width: 500px;
  width: 90%;
  position: fixed;
  background-color: white;
  margin: auto;
  left: 0;
  right: 0;
  height: 300px;
  top: 0;
  bottom: 0;
  padding: 1rem;
  border-radius: 10px;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.9);
  transition: all 300ms;
}
.continue-button-confirm-login {
  position: absolute;
  right: 50px;
  bottom: 20px;
}
.continue-button-confirm-login button {
  border: none;
  background-color: #09203f;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 200ms;
}
.continue-button-confirm-login button:hover {
  border: none;
  background-color: #144686;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 200ms;
}

.login-button {
  position: absolute;
  right: 10px;
  top: 15px;
}
/* ipad pro */
@media (min-width: 1100px) and (max-width: 500px) {
  .login-box-opened {
    /* max-width: 600px; */
    min-width: 400px !important;
  }
  .login-box-closed {
    max-width: 600px;
  }

  .login-button {
    position: absolute;
    right: 70px;
    top: 15px;
  }
}
/* #root
  > div
  > div:nth-child(3)
  > div
  > div.login-box-opened
  > div.Agents-Div
  > div {
  margin-top: 22px;
} */
.mobile {
  display: none !important;
}
.new-user-div {
  margin-top: 0.5rem !important;
}
.payment-Shortcut {
  margin-top: 10px;
}
/* phones */
@media (max-width: 1100px) {
  .login-button {
    right: 55px;
  }
}
@media (max-width: 600px) {

  .logo-header {
    height: 50px !important;
    margin: 0;
  }

  .header-mobile-closed,
  .header-mobile-open {
    padding: 10px 0px !important;
  }

  .payment-Shortcut {
    margin-top: 10px;
  }

  .new-user-div {
    margin-top: 0.5rem !important;
    margin-bottom: 0.25rem;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block !important;
    margin-top: 10px;
  }

  .login-box-opened > .Agents-Div button,
  .login-box-opened > .Customers-Div button {
    opacity: 1 !important;
    width: 80%;
    padding: 10px;
    max-width: 150px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    margin: 5px;
    background-color: #266f00;
  }
  .login-box-opened {
    display: block;
    height: 615px;
    width: calc(95% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }

  .login-box-closed {
    display: block;
    height: 600px;
    width: calc(90% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }
  .login-box-opened-small {
    display: block;
    height: 350px;
    width: calc(95% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }
  .login-box-closed-small {
    display: block;
    height: 350px;
    width: calc(90% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }

  .Agents-Div,
  .Customers-Div {
    float: none;
  }

  .Agents-Div h3,
  .Customers-Div h3 {
    font-size: 24px;
    padding: 10px;
  }

  .Customers-Div {
    padding-top: 0;
  }

  .login-button {
    position: absolute;
    right: 70px;
    top: 15px;
  }

  .close-login-menu-icon {
    position: absolute;
    top: 10px;
  }
  .confirm-login-message-component-open {
    max-width: 500px;
    width: 90%;
    position: fixed;
    background-color: white;
    margin: auto;
    left: 0;
    right: 0;
    height: 300px;
    top: 0;
    bottom: 0;
    padding: 1rem;
    border-radius: 10px;
    z-index: 21;
    transition: all 300ms;
  }
  .confirm-login-message-component-closed {
    max-width: 500px;
    width: 90%;
    position: fixed;
    background-color: white;
    margin: auto;
    left: 0;
    right: 0;
    height: 300px;
    top: 0;
    bottom: 0;
    padding: 1rem;
    border-radius: 10px;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
    transform: scale(0.9);
    transition: all 300ms;
  }
  .error-message-shown {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 10px;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    opacity: 1;
    z-index: 100;
    font-size: 14px;
  }
  .error-message-hidden {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    transform: translateY(-200px);
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  .message-shown {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 10px;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    opacity: 1;
    z-index: 100;
    font-size: 14px;
  }
  .message-hidden {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    transform: translateY(-100px);
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  /* .MuiFormControlLabel-root:first-child {
    margin-left: 0px !important;
    margin-right: 0px;
  } */

  .Customers-Div,
  .Agents-Div {
    padding-top: 0px !important;
  }
  .login-container {
    margin-top: 40px;
  }
}

.weather-message {
  color: #a30000;
  font-weight: bold;
}
.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  margin-bottom: 10px;
}

.Customers-Div,
.Agents-Div {
  width: 250px;
  padding-top: 10px;
  padding: 0px;
  height: 500px;
  border: 2px solid WHITE;
}

.Customers-Div img,
.Agents-Div img {
  border-radius: 50%;
  border: 5px solid #01224f;
  margin: 8%;
  height: 115px;
}

.Customers-Div img {
  margin-bottom: 2% !important;
}

.MuiFormGroup-root {
  display: block !important;
}

#SHOW-ME {
  display: block !important;
}
#HIDE-ME,
.HIDE-ME {
  display: none !important;
}
#FLEX-ME {
  display: flex !important;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 0px;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  padding: 3px;
  padding-right: 8px;
}
/* 
.MuiFormControlLabel-root:first-child {
  margin-left: 15px;
} */
@media (min-width: 500px) {
  .MARG-50 {
    margin-top: 50px;
  }
}

#STRETCH-ME {
  max-width: 750px !important;
  list-style: none;
}

.grid-item.popup {
  height: 200px !important;
  width: 235px !important;
  text-align: center;
}

.grid-item.popup > h2 {
  text-align: center !important;
}
.grid-item.popup:after {
  display: none;
}

.grid-item.popup > h2.blue-color:after,
.grid-item.popup > div > h2.blue-color:after {
  display: none;
}

.grid-item.popup > input[type="radio"] {
  border: none;
}

.SPOTTED,
.grid-item.popup > img {
  display: none !important;
}
#GRID-ME {
  display: flex !important;
  list-style: none;
}

.field-container#HIDE-ME {
  display: block !important;
}
@media (max-width: 600px) {
  .field-container#HIDE-ME {
    display: none !important;
  }
  #GRID-ME {
    display: grid !important;
    list-style: none;
  }
  #EXTEND-ME {
    margin-top: 55px !important;
  }
  .Agents-Div {
    margin-top: 55px !important;
  }
  .Customers-Div,
  .Agents-Div {
    height: 200px !important;

    margin: 10px auto;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgb(0 0 0 / 15%);
    width: 80%;
    text-align: center;
    cursor: pointer;
    /* transition: 0.3s; */
    align-items: center;
    display: list-item;
    padding: 1rem;
  }
  .Customers-Div > .field-container,
  .Agents-Div > .field-container {
    display: none;
  }
}

#EXTEND-ME {
  height: 465px !important;
  background: NONE !important;
  box-shadow: NONE !important;
}
#HIDE-ME\ EXTEND-ME {
  display: none !important;
}

.backButton {
  left: 7px !important;
  top: 7px;
}

.backButton > button {
  background: #f0f0f0;
  border: 1px solid;
}
.get-a-quote-footer {
  position: absolute;
  bottom: 5px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
.get-a-quote-footer ul {
  display: flex;
  padding: 0;
}
.get-a-quote-footer li {
  margin: 1rem;
}
.get-a-quote-copy-rigth {
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10px;
}
.get-a-quote-footer,
.get-a-quote-copy-rigth {
  display: none;
}

.footer {
  position: relative;
  background-color: #01224f;
  color: white;
  margin-top: 5rem;
  padding: 1rem;
  background-image: url(https://www.uaig.net/ns/images/footerbg.png);
}
.footer-div {
  display: flex;
  justify-content: space-between;
  color: white;
  width: 80%;
  margin: auto;
}
.footer-first-column {
  display: flex;
}

.footer-links {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.footer-links:hover {
  text-decoration: underline;
}
.logo-and-social-media,
.footer-third-column {
  text-align: center;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
}
.logo-and-social-media-mobile {
  text-align: center;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
}
.footer-logo {
  height: 65px;
}
.icon-footer {
  margin: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.icon-footer:hover {
  color: rgb(199, 199, 199);
  transition: 0.2s;
  transform: translateY(-5px);
}
.footer-image {
  cursor: pointer;
  transition: 0.2s;
}
.footer-image:hover {
  filter: brightness(0.6);
  transition: 0.2s;
}
@media only screen and (max-width: 600px) {
  .footer {
    text-align: center;
    border-radius: 0px;
    background-image: none;
    background-color: rgb(9, 32, 63);
  }
  .geo-trust-div {
    background-image: linear-gradient(rgb(9, 32, 63), rgb(0, 0, 0));
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    padding-bottom: 10px;
  }

}
@media only screen and (min-width: 768px) {
  .footer-mobile{
    display:none !important;
  }
}
@media only screen and (max-width: 767px) {
  .footer-desktop{
    display:none !important;
  }
}
@media only screen and (min-width: 600px) {
  .geo-trust-div {
    background-image: linear-gradient(rgb(0, 34, 80), rgb(0, 0, 0));
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    padding-bottom: 10px;
  }
 
}

@media only screen and (min-width: 600px) {
  .about-us {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .about-us-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .about-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    align-items: center;
    display: flex;
  }
  .about-us-info-div {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    margin-top: 3rem;
  }
  .about-us-info {
    max-width: 1200px;
    margin: auto;
    margin-top: 3rem;
  }
  .about-us-column-2 {
    width: 40%;
  }
  .about-us-column-1 {
    width: 55%;
    margin-right: 2rem;
  }
  .about-us-picture {
    width: 85%;
    text-align: center;
  }
  .our-mission-div {
    margin-top: 3rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .about-us {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .about-us-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .about-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .about-us-info-div {
    flex-wrap: wrap;
  }
  .about-us-picture {
    width: 90%;
    margin-top: 2rem;
  }
  .about-us-column-1 {
    margin-top: 2rem;
    margin: 1rem;
  }
  .about-us-column-2 {
    margin: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .baa-banner {
    max-width: 700px;
    margin: 56px auto 135px auto;
    padding: 10px 10px 100px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    margin: auto;
    opacity: 1;
    transition: all 500ms;
  }
  .baa-banner-not-rendered {
    max-width: 700px;
    margin: 56px auto 135px auto;
    padding: 10px 10px 100px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    margin: 2rem;
    margin: auto;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .baa {
    font-size: 45px;
    border-bottom-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
  }

  .baa-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .txt-input-div {
    width: 60%;
    margin: auto;
    margin-top: 2rem;
  }
  .txt-input-select {
    padding: 1rem;
    font-size: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}
@media only screen and (max-width: 600px) {
  .baa-banner {
    max-width: 80%;
    margin: 56px auto 135px auto;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    margin: auto;
    opacity: 1;
    transition: all 500ms;
  }
  .baa-banner-not-rendered {
    max-width: 80%;
    margin: 56px auto 135px auto;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    margin: auto;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .baa {
    font-size: 30px;
    border-bottom-width: 1px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
  }

  .baa-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .txt-input-div {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
  .txt-input-select {
    padding: 1rem;
    font-size: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}

@media only screen and (min-width: 600px) {
  .careers {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .careers-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .career-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .career-image {
    width: 600px;
    border-radius: 5px;
  }
  .career-wrapper {
    display: flex;
    width: 80%;
    justify-content: center;
    text-align: left;
  }

  .careers-div {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .component-to-display-div {
    width: 60%;
    display: flex;
    justify-content: center;
  }
  .careers-info-div {
    flex-wrap: wrap;
  }
  .careers-navigation {
    margin-right: 4rem;
    width: 200px;
    padding: 2rem;
    height: 100%;
  }
  .careers-navigation-inner-div {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
  }
  .careers-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
  }
  .careers-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }
}

@media only screen and (max-width: 600px) {
  .careers {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .careers-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .career-uaic-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .career-image {
    width: 100%;
    border-radius: 5px;
  }
  .career-wrapper {
    width: 80%;
  }

  .careers-div {
    margin-top: 1rem;
    margin-bottom: 5rem;
    height: 100%;
    width: 100%;
  }
  .component-to-display-div {
    padding-top: 5rem;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
  }
  .careers-info-div {
    flex-wrap: wrap;
  }
  .careers-navigation {
    margin-right: 4rem;
    width: 200px;
  }
  .careers-navigation-link-item {
    color: rgb(100, 100, 100);
    cursor: pointer;
  }
  .careers-navigation-link-item:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }

  .navigation-mobile-closed {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 25px;
    transition: 0.1s;
    overflow: hidden;
  }
  .navigation-mobile-open {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 380px;
    transition: 0.3s;
    overflow: hidden;
  }
}

@media only screen and (min-width: 600px) {
  .careersFirstPage {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .careersFirstPage-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .careersFirstPage {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .careersFirstPage-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .Accounting {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .Accounting-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .Accounting {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .Accounting-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .Diversity {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .Diversity-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .Diversity {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .Diversity-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .CareersFAQ {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .CareersFAQ-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .CareersFAQ {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .CareersFAQ-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .Benefits {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .Benefits-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
  .header-picture {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .Benefits {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .Benefits-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
  .header-picture {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .CareersClaim {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .CareersClaim-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .CareersClaim {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .CareersClaim-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .Corporate {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .Corporate-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .Corporate {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .Corporate-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

.It {
  opacity: 1;
  transition: 0.2s;
  width: 80%;
}
.It-not-rendered {
  opacity: 0;
  transition: 0.2s;
  transform: translateY(10px);
}
@media only screen and (min-width: 600px) {
  .It {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .It-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .It {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .It-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .Underwriting {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .Underwriting-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .Underwriting {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .Underwriting-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 600px) {
  .JobFairs {
    opacity: 1;
    transition: 0.2s;
    width: 80%;
  }
  .JobFairs-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}
@media only screen and (max-width: 600px) {
  .JobFairs {
    opacity: 1;
    transition: 0.2s;
    width: 100%;
  }
  .JobFairs-not-rendered {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(10px);
  }
}

@media only screen and (min-width: 900px) {
  .request-help-open,
  .request-help-hidden {
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 600px;
    /* height: 80%; */
    padding: 1rem;
    border-radius: 10px;
    margin: auto;
    inset: 0;
    z-index: 51;
    overflow-y: auto;
    /* max-height: 520px; */
    transition: 0.3s;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }

  .request-help-open {
    opacity: 1;
  }
  .request-help-hidden {
    transform: scale(0.95);
    pointer-events: none;
    opacity: 0;
  }
  .request-help-component-header {
    display: flex;
    justify-content: space-between;
  }
  .request-help-component-inner-header {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }
  .request-help-content {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .request-help-back-button {
    position: absolute;
    left: 25px;
    cursor: pointer;
  }
  .request-help-continue-button-container {
    display: flex;
    justify-content: center;
  }
  .date-picker {
    padding: 1rem;
    border-radius: 5px;
    font-size: 18px;
    max-width: 245px;
    width: 100%;
    border: 1px solid rgb(196, 196, 196);
  }
  .requested-help-buttons {
    margin: 0 10px;
    background-color: #266f00;
    color: white;
    padding: 10px;
    font-size: 18px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  .requested-help-buttons-container {
    text-align: center;
    margin-bottom: 200px;
  }
  .requested-help-container {
    width: 100%;
    text-align: center;

    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 900px) {
  .request-help-open {
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 85%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2rem;
    border-radius: 10px;
    margin: auto;
    inset: 0;
    z-index: 51;
    transition: 0.3s;
    overflow-y: auto;
    font-size: 12px;
    /* max-height: 520px; */
  }
  .request-help-hidden {
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 85%;
    min-height: 100px;
    max-height: 80%;
    padding: 1rem;
    border-radius: 10px;
    margin: auto;
    inset: 0;
    z-index: 51;
    transition: 0.3s;
    opacity: 0;
    transform: scale(0.95);
    pointer-events: none;
    font-size: 12px;
  }
  .request-help-component-header {
    display: flex;
    justify-content: space-between;
  }
  .request-help-component-inner-header {
    display: flex;
    justify-content: center;
  }
  .request-help-component-inner-header h2 {
    margin-top: 2rem;
  }
  .request-help-content {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .request-help-back-button {
    position: absolute;
    left: 25px;
    cursor: pointer;
  }
  .request-help-continue-button-container {
    display: flex;
    justify-content: center;
  }
  .date-picker {
    padding: 1rem;
    border-radius: 5px;
    z-index: 999;
    width: 100%;
    max-width: 245px;
    border: 1px solid rgb(196, 196, 196);
  }
  .lien_holder_requested_help_page_container {
    margin-top: 100px;
  }
  .lien_holder_requested_help_page_container > h1 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .requested-help-buttons {
    margin-top: 10px;
    background-color: #266f00;
    color: white;
    padding: 10px;
    font-size: 18px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  .requested-help-buttons-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 200px;
  }
  .requested-help-container {
    width: 100%;
    text-align: center;
    min-height: 38vh;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: -50px;
  }
}

.how-can-we-help-input {
  width: 100%;
}

.request-help-back-button {
  position: absolute;
  top: 15px;
  left: 15px;
}

.request-help-continue-button,
.verify-me,
.request-help-submit-button {
  background-color: #266f00 !important;
  color: white !important;
}

.request-help-header {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(160, 160, 160);
}

@media only screen and (max-width: 600px) {
  .request-help-parent {
    display: inline-block;
    width: 95%;
    margin: 0;
  }
  .verification-options {
    display: block !important;
  }

  .verification-options-policy {
    margin-bottom: 5px;
  }
  .verification-options-header {
    margin-right: 0% !important;
  }
  .policy-related-container input,
  .claims-related-container input {
    width: 250px;
  }
  .policy-related-inner h3,
  .claims-related-inner h3 {
    display: none;
  }

  .reason-dropdown {
    width: 278px !important;
  }

  .request-help-open {
    max-height: 420px;
  }
}

.MuiButtonBase-root.Mui-disabled {
  background-color: #c0c0c0 !important;
  color: black;
}

.claims-puff-loader {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: 2px solid;
}

.requested-help-bread-crumbs {
  margin-left: 10%;
}

.input-set-width {
  width: 280px !important;
}

@media only screen and (min-width: 600px) {
  .insurance {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .insurance-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .insurance-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .insurance-info-div {
    margin: auto;
    max-width: 900px;
    margin-top: 3rem;
  }
  .cards-div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 1000px;
    justify-content: left;
    margin-left: -25px;
  }

  .insurance-card-1,
  .insurance-card-2 {
    height: 640px;
    width: 350px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    position: relative;
  }
  .insurance-images {
    width: 100%;
  }
  .insurance-list-item {
    display: flex;
    align-items: center;
    margin: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .insurance {
    margin: auto;
    margin: 1rem;
    transition: all 500ms;
  }
  .insurance-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .insurance-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }
  .insurance-info-div {
    margin: auto;
    margin-top: 3rem;
  }
  .cards-div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    margin-left: -1rem;
  }

  .insurance-card-1,
  .insurance-card-2 {
    height: 640px;
    width: 350px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    border-radius: 5px;
    padding: 2rem;
    position: relative;
  }
  .insurance-images {
    width: 100%;
  }
  .insurance-list-item {
    display: flex;
    align-items: center;
    margin: 1rem;
  }
}

.leinForm {
  display: flex;
  justify-content: center;
  width: 90vw;
  margin: auto;
}
.leinInput {
  float: left;
  width: 20%;
  height: 825px;
  margin-top: 25px;
}

.leinFieldset {
  border: 1px solid #ced4da;
  display: inline-block;
  background-color: white;
  margin-top: 25px;
  padding: 26px;
}

.search {
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 25%;
}

.leinFieldset fieldset {
  border: none;
  font-size: 18 px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.leinFieldset input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.leinFieldset button {
  padding: 5px 20px 5px 20px;
  color: #ffffff;
  border: 1px solid #266f00 !important;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 35%;
  font-size: 13px !important;
  text-shadow: 1px 1px 0px #222;
  background-color: #076415;
}

.logout {
  float: right;
  padding: 10px;
}

.leinDetails h1 {
  padding: 10px;
}

.half-left {
  float: left;
  width: 25%;
  padding-left: 5%;
}

.middle-left {
  float: left;
  width: 25%;
}

.third-left {
  float: left;
}

.ribbon {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  width: 345px;
  height: 400px;
  text-align: right;
  pointer-events: none;
}
.ribbon span {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 325px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#2989d8 0%, #1e5799 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
  position: absolute;
  top: 73px;
  left: -65px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 8px solid #1e5799;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #1e5799;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 8px solid transparent;
  border-right: 8px solid #1e5799;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #1e5799;
}
.app-icons {
  margin-top: 10px;
  display: inline-block;
}
.app-icons-download {
  margin: 2px;
}
@media only screen and (min-width: 500px) {
  #MOBILE-ONLY {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .banner-payment-options {
    margin-top: 12px;
    margin-bottom:2%;
  }
  .ribbon {
    left: calc(8% - 50px);
    top: 85px;
  }
  .home-page {
    margin-top: -30px;
    opacity: 1;
    transition: 0.4s;
  }
  .home-page-not-rendered {
    margin-top: -30px;
    opacity: 0;
    transition: 0.4s;
  }
  .home-page-home-text {
    font-size: 50px;
    padding-bottom: 10px;
  }
  .home-picture {
    background-color: #537895;
    background-image: linear-gradient(360deg, #5e7a86 0%, #b9ccd2 74%);
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    height: 450px;
    width: calc(100% - 100px);
    padding: 50px;
    padding-top: 70px;
    margin-top: -100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .waves-background {
    background-color: #5e7a86;
  }
  /* .home-page-left-div {
    width: 55%;
    text-align: center;
    margin-left: 50px;
  } */
  .zip-input {
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    width: 170px;
  }
  .start-quote-button {
    padding: 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    width: 200px;
    transition: 0.2s;
    background-color: #266f00;
    color: black;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s;

    border: 3px solid #4c7a94;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    width: 200px;
    background-color: rgba(255, 255, 225, 0.5);
    font-weight: bold;
  }
  .start-quote-button:hover {
    background-color: rgb(199, 199, 199);
    transition: 0.2s;
  }
  .slide-show {
    display: block;
    z-index: 3;
    width: 100%;
    text-align: center;
    max-width: 1300px;
  }

  .carousel .slide img {
    max-width: 650px !important;
    height: 375px;
  }

  .app-icons {
    margin-top: 10px;
    display: block;
  }
  .app-icons-download {
    height: 40px;
  }
  .grid-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 2rem;
  }
  .grid-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 250px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    align-items: centerS;
  }
  .grid-item:hover {
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    transform: translateY(-3px);
  }

  .home-page-about {
    width: 80%;
    margin: auto;
  }

  .home-page-become-an-agent {
    width: 100%;
    height: 350px;
    background-color: rgb(240, 240, 240);
    margin-bottom: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-page-become-an-agent-picture {
    height: 200px;
    margin: 3rem;
    border-radius: 5px;
  }
  .select-state-select {
    padding: 1rem;
    margin: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
  }
  .select-state-select-card {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
  }
  .grid-item-picture {
    width: 85%;
    min-height: 165px;
    max-height: 165px;
    object-fit: contain;
  }

  .home-page-card-button,
  .home-page-button {
    width: 150px;
    height: 50px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #266f00;
    padding: 10px;
    transition: 0.1s;
    cursor: pointer;
    text-align: center;
  }
  .home-page-card-button:hover,
  .home-page-button:hover {
    transition: 0.1s;
    filter: brightness(1.1);
  }
  .home-page-card-button-off,
  .home-page-button-off {
    width: 150px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #266f00;
    padding: 10px;
    transition: 0.2s;
    opacity: 0.65;
    cursor: not-allowed;
    height: 50px;
  }

  .continue-previous-quote-component-shown {
    position: fixed;
    max-height: 340px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 450px;
    transition: 0.3s;
    z-index: 20;
  }
  .continue-previous-quote-component-hidden {
    position: fixed;
    max-height: 360px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 450px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    transform: scale(0.9);
  }

  .continue-button-confirm-login-home {
    position: relative;
    margin-top: 3rem;

    display: flex;
    justify-content: end;
  }
  .continue-button-confirm-login-home button {
    position: absolute;
    border: none;
    background-color: #09203f;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    width: 150px;
    font-size: 16px;
  }
  .continue-button-confirm-login-home button:hover {
    border: none;
    background-color: #144686;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
  .video-carousel-container > h2 {
    display: none;
  }

  /* waves */
}
/* @media only screen and (min-width: 850px) {
  /* .carousel .slide img {
    max-width: 1400px !important;
    min-width: 800px !important;
  } */
/* .home-picture {
    padding-top: 75px;
    padding-bottom: 0px;
  } */

@media screen and (max-width: 1300px) and (min-width: 600px) {
  .ribbon {
    left: calc(8% - 75px);
    top: 85px;
  }
  .grid-container {
    width: 100%;
  }
  .grid-item {
    width: 215px;
  }
}@media screen and (max-width: 900px) and (min-width: 810px) {
  .find-an-agent-div{
    display:inline-block !important;
  }
}
@media screen and (min-width: 1301px) {
  .ribbon {
    left: 8%;
    top: 85px;
  }
  .grid-container {
    width: 90%;
    max-width: 1200px;
  }
  .grid-item {
    width: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .home-page-left-div {
    margin-bottom: -50px !important;
}
}
@media only screen and (max-width: 600px) {

.find-an-agent-div{
  display:inline-flex !important;
}

  .find-an-agent-header{
   font-size: 24px !important;
  }

  #root > div > main > div > div.grid-container > div:nth-child(6) {
    display: none;
  }

  #root > div > main > div > div.grid-container > a:nth-child(6) {
    display: none;
  }

  .ribbon span {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 224px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#2989d8, #1e5799);
    box-shadow: 0 3px 10px -5px rgb(0 0 0);
    position: absolute;
    top: 37px;
    left: -41px;
  }
  #root
    > div
    > div.home-page
    > div.home-picture
    > div.home-page-left-div
    > div:nth-child(3) {
    margin-top: -30px;
  }

  .ribbon {
    left: calc(7% - 12px);
    top: 70px;
  }
  .third-column {
    text-align: center;
  }
  #root > div > div:nth-child(5) > div > div > div > div.third-column > div {
    display: block !important;
  }

  .grid-item > h2.blue-color,
  .grid-item > div > h2.blue-color {
    text-align: left;
  }
  .Agents-Div > h2.blue-color,
  .Customers-Div > h2.blue-color {
    text-align: center;
  }

  .grid-item > h2.blue-color:after,
  .grid-item > div > h2.blue-color:after,
  .grid-item > .make_a_payment > h2.blue-color:after,
  .grid-item > .make_a_payment > div > h2.blue-color:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-right: 5px solid #003a93;
    border-top: 5px solid #003a93;
    transform: rotate(45deg);
    margin-right: 0.5em;
    float: right;
    margin-top: 10px;
    font-weight: bold;
    color: #003a93;
    text-align: left !important;
  }

  .home-page {
    margin-top: -30px;
    opacity: 1;
    transition: 0.3s;
  }
  .home-page-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0.97);
  }
  .home-page-home-text {
    font-size: 40px;
    padding-bottom: 10px;
  }
  .home-picture {
    background-color: #537895;
    background-image: linear-gradient(360deg, #5e7a86 0%, #b9ccd2 74%);
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    height: 100%;
    margin-top: -100px;
    width: calc(100vw - 40px);
    padding: 20px;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .card-text {
    width: 0px;
    opacity: 0;
  }
  .zip-input {
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    width: 170px;
  }
  .start-quote-button {
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s;
    color: black;
    border: 3px solid #4c7a94;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    width: 200px;
    background-color: rgba(255, 255, 225, 0.5);
    font-weight: bold;
  }

  .waves-background {
    background-color: #5e7a86;
  }

  .slide-show {
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-top: 55px;
  }

  .slide-container {
    float: right;
    width: 100%;
  }
  .grid-container {
    width: 95%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 10px;
  }
  .grid-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 30px;
    width: 80%;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    margin: 10px;
    padding: 1rem;
  }
  .grid-item:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .about-us-column-2 > li {
    text-align: left;
  }
  .home-page-about {
    width: 95%;
    margin: auto;
    text-align: justify;
  }
  .home-page-become-an-agent {
    width: calc(100vw - 40px);
    height: 100%;
    background-color: rgb(240, 240, 240);

    margin-bottom: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;

    text-align: center;
  }
  .home-page-become-an-agent-picture {
    width: 95%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .select-state-select {
    padding: 1rem;
    margin: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
  }
  .select-state-select-card {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
  }
  .grid-item-picture {
    width: 0px;
    pointer-events: none;
    opacity: 0;
  }
  .home-page-card-button {
    width: 0px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    transition: 0.2s;
    opacity: 0;
    pointer-events: none;
  }

  .app-icons {
    margin-top: 10px;
    display: inline-block;
  }
  .app-icons-download {
    height: 40px;
  }
  .continue-previous-quote-component-shown {
    position: fixed;
    height: 60%;
    max-height: 340px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 85%;
    transition: 0.3s;
    z-index: 20;
  }
  .continue-previous-quote-component-hidden {
    position: fixed;
    height: 60%;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 85%;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    transform: scale(0.9);
  }

  .continue-button-confirm-login-home {
    position: absolute;
    bottom: -30px;
    display: flex;
    justify-content: center;
    left: 30%;
  }
  /* .continue-button-confirm-login-home button {
    position: absolute;
    border: none;
    background-color: #09203f;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    width: 75%;
    max-width: 175px;
    font-size: 16px;
  } */
  .about-us-picture {
    width: 100%;
    text-align: center;
  }

  .about-us-info {
    width: 90vw;
    margin: auto;
    margin-top: 3rem;
  }
  .our-mission-div {
    width: 80vw;
    margin: auto;
    margin-top: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
  }
  .video-carousel-container {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .video-carousel-container > h2 {
    padding-left: 15px;
  }
  /* waves */
}

.continue-previous-quote-dark-background-on {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 20;
  transition: 0.3s;
}
.continue-previous-quote-dark-background-off {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 1vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 10vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 30s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 14s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 17s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 20s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 27s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/

.mobileOnly {
  display: none;
}
.desktopOnly {
  display: inline-block;
}

@media (max-width: 768px) {
  .mobileOnly {
    display: inline-block;
    padding-right: 5px;
  }
  .desktopOnly {
    display: none;
  }
  .waves {
    height: 40px;
    min-height: 70px !important;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
  #STRETCH-ME {
    max-width: 650px !important;
    list-style: none;
  }
}

.get-a-quote-component-shown,
.otp-component-shown {
  position: fixed;
  z-index: 23;
  width: 300px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 10px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  text-align: center;
  transition: 0.3s;
}
.get-a-quote-component-hidden,
.otp-component-hidden {
  position: fixed;
  z-index: 0;
  width: 300px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 10px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  text-align: center;
  transform: scale(0.9);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
}

.grid-container {
  max-width: 1200px;
}

#MOBILE-ONLY > a > h2 {
  text-align: left !important;
}

.start-quote-button-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.banner-payment-options {
  font-size: 15px;
  cursor: pointer;
}

.home-page-left-div {
  text-align: center;
}

/* @media screen and (max-width: 800px) {
  .home-picture {
    display: inline-table !important;
  }
}
@media screen and (min-width: 600px) {
  .home-page-left-div {
    width: 100% !important;
    text-align: center;
  }
} */

@media screen and (min-width: 750px) {
  .mobileOnly {
    display: none;
  }
}
@media screen and (max-width: 750px) and (min-width: 525px) {
  .home-picture {
    height: 718px !important;
    display: grid !important;
  }
  .home-picture-div {
    display: block !important;
  }

  .renew-policy {
    display: block;
  }
}

.home-page-left-div {
  width: 100%;
}

.claims-button{
  margin-bottom: 6%;
}
.first-page {
  padding: 1rem;
}
.native-select-required-field {
  font-size: 13px;
  margin-left: 20px;
  margin-top: -10px;
  color: red;
}
.row {
  margin-left: 30px;
}
.first-page-input-div {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .first-page-input {
    padding: 10px;
    border: none;
    background-color: rgb(240, 240, 240);
    font-size: 20px;
    margin-right: 1rem;
    margin-top: 5px;
    border-radius: 5px;
    transition: 0.2s;
    width: 300px;
  }
  .first-page-input:focus {
    outline: none;
    background-color: rgb(215, 215, 215);
    transition: 0.2s;
  }
}
@media only screen and (max-width: 600px) {
  .first-page-input {
    padding: 10px;
    border: none;
    background-color: rgb(240, 240, 240);
    font-size: 20px;
    margin-right: 1rem;
    margin-top: 5px;
    border-radius: 5px;
    transition: 0.2s;
    width: 80%;
  }
  .first-page-input:focus {
    outline: none;
    background-color: rgb(215, 215, 215);
    transition: 0.2s;
  }
}
.otp-component-shown {
  height: 240px;
}
.otp-component-hidden {
  height: 240px;
}
.continue-button-confirm-login-home button {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 1rem;
  transition: all 200ms;
  opacity: 0.65;
  cursor: not-allowed;
}
.invalid-message {
  width: 100%;
}
.continue-button-confirm button {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 1rem;
  transition: all 200ms;
  cursor: not-allowed;
}
.ribbon {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  width: 345px;
  height: 400px;
  text-align: right;
  pointer-events: none;
}
.ribbon span {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 325px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#01224f 0%, #01224f 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
  position: absolute;
  top: 73px;
  left: -65px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 8px solid #01224f;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #01224f;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 8px solid transparent;
  border-right: 8px solid #01224f;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #01224f;
}
.app-icons {
  margin-top: 10px;
  display: inline-block;
}
.app-icons-download {
  margin: 2px;
}
@media only screen and (min-width: 500px) {
  #MOBILE-ONLY {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .banner-payment-options {
    margin-top: 12px;
    margin-bottom:2%;
  }
  .ribbon {
    left: calc(8% - 50px);
    top: 85px;
  }
  .home-page {
    margin-top: -30px;
    opacity: 1;
    transition: 0.4s;
  }
  .home-page-not-rendered {
    margin-top: -30px;
    opacity: 0;
    transition: 0.4s;
  }
  .home-page-home-text {
    font-size: 50px;
    padding-bottom: 10px;
  }
  .home-picture {
    background-color: #537895;
    background-image: linear-gradient(360deg, #274060 0%, #537895 74%);
  
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    height: 450px;
    width: calc(100% - 100px);
    padding: 50px;
    padding-top: 70px;
    margin-top: -100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .waves-background {
    background-color: #274060;
  }
  /* .home-page-left-div {
    width: 55%;
    text-align: center;
    margin-left: 50px;
  } */
  .zip-input {
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    width: 170px;
  }
  .start-quote-button {
    padding: 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    width: 200px;
    transition: 0.2s;
    background-color: #266f00;
    color: black;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s;

    border: 3px solid #4c7a94;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    width: 200px;
    background-color: rgba(255, 255, 225, 0.5);
    font-weight: bold;
  }
  .start-quote-button:hover {
    background-color: rgb(199, 199, 199);
    transition: 0.2s;
  }
  .slide-show {
    display: block;
    z-index: 3;
    width: 100%;
    text-align: center;
    max-width: 1300px;
  }

  .carousel .slide img {
    max-width: 650px !important;
    height: 375px;
  }

  .app-icons {
    margin-top: 10px;
    display: block;
  }
  .app-icons-download {
    height: 40px;
  }
  .grid-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 2rem;
  }
  .grid-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 250px;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    align-items: centerS;
  }
  .grid-item:hover {
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    transform: translateY(-3px);
  }

  .home-page-about {
    width: 80%;
    margin: auto;
  }

  .home-page-become-an-agent {
    width: 100%;
    height: 350px;
    background-color: rgb(240, 240, 240);
    margin-bottom: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-page-become-an-agent-picture {
    height: 200px;
    margin: 3rem;
    border-radius: 5px;
  }
  .select-state-select {
    padding: 1rem;
    margin: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
  }
  .select-state-select-card {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
  }
  .grid-item-picture {
    width: 85%;
    min-height: 165px;
    max-height: 165px;
    object-fit: contain;
  }

  .home-page-card-button,
  .home-page-button {
    width: 150px;
    height: 50px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #266f00;
    padding: 10px;
    transition: 0.1s;
    cursor: pointer;
    text-align: center;
  }
  .home-page-card-button:hover,
  .home-page-button:hover {
    transition: 0.1s;
    filter: brightness(1.1);
  }
  .home-page-card-button-off,
  .home-page-button-off {
    width: 150px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #266f00;
    padding: 10px;
    transition: 0.2s;
    opacity: 0.65;
    cursor: not-allowed;
    height: 50px;
  }

  .continue-previous-quote-component-shown {
    position: fixed;
    max-height: 340px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 450px;
    transition: 0.3s;
    z-index: 20;
  }
  .continue-previous-quote-component-hidden {
    position: fixed;
    max-height: 360px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 450px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    transform: scale(0.9);
  }

  .continue-button-confirm-login-home {
    position: relative;
    margin-top: 3rem;

    display: flex;
    justify-content: end;
  }
  .continue-button-confirm-login-home button {
    position: absolute;
    border: none;
    background-color: #09203f;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    width: 150px;
    font-size: 16px;
  }
  .continue-button-confirm-login-home button:hover {
    border: none;
    background-color: #144686;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
  .video-carousel-container > h2 {
    display: none;
  }

  /* waves */
}
/* @media only screen and (min-width: 850px) {
  /* .carousel .slide img {
    max-width: 1400px !important;
    min-width: 800px !important;
  } */
/* .home-picture {
    padding-top: 75px;
    padding-bottom: 0px;
  } */

@media screen and (max-width: 1300px) and (min-width: 600px) {
  .ribbon {
    left: calc(8% - 75px);
    top: 85px;
  }
  .grid-container {
    width: 100%;
  }
  .grid-item {
    width: 215px;
  }
}
@media screen and (min-width: 1301px) {
  .ribbon {
    left: 8%;
    top: 85px;
  }
  .grid-container {
    width: 90%;
    max-width: 1200px;
  }
  .grid-item {
    width: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .home-page-left-div {
    margin-bottom: -50px !important;
}
}
@media only screen and (max-width: 600px) {
  #root > div > main > div > div.grid-container > div:nth-child(6) {
    display: none;
  }

  #root > div > main > div > div.grid-container > a:nth-child(6) {
    display: none;
  }

  .ribbon span {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 224px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#01224f, #01224f);
    box-shadow: 0 3px 10px -5px rgb(0 0 0);
    position: absolute;
    top: 37px;
    left: -41px;
  }
  #root
    > div
    > div.home-page
    > div.home-picture
    > div.home-page-left-div
    > div:nth-child(3) {
    margin-top: -30px;
  }

  .ribbon {
    left: calc(7% - 12px);
    top: 70px;
  }
  .third-column {
    text-align: center;
  }
  #root > div > div:nth-child(5) > div > div > div > div.third-column > div {
    display: block !important;
  }

  .grid-item > h2.blue-color,
  .grid-item > div > h2.blue-color {
    text-align: left;
  }
  .Agents-Div > h2.blue-color,
  .Customers-Div > h2.blue-color {
    text-align: center;
  }

  .grid-item > h2.blue-color:after,
  .grid-item > div > h2.blue-color:after,
  .grid-item > .make_a_payment > h2.blue-color:after,
  .grid-item > .make_a_payment > div > h2.blue-color:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-right: 5px solid #003a93;
    border-top: 5px solid #003a93;
    transform: rotate(45deg);
    margin-right: 0.5em;
    float: right;
    margin-top: 10px;
    font-weight: bold;
    color: #003a93;
    text-align: left !important;
  }

  .home-page {
    margin-top: -30px;
    opacity: 1;
    transition: 0.3s;
  }
  .home-page-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0.97);
  }
  .home-page-home-text {
    font-size: 40px;
    padding-bottom: 10px;
  }
  .home-picture {
    background-color: #537895;
    /* background-image: linear-gradient(360deg, #5e7a86 0%, #b9ccd2 74%); */
    background-image: linear-gradient(360deg, #274060 0%, #537895 74%);
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    height: 100%;
    margin-top: -100px;
    width: calc(100vw - 40px);
    padding: 20px;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .card-text {
    width: 0px;
    opacity: 0;
  }
  .zip-input {
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin: 10px;
    width: 170px;
  }
  .start-quote-button {
    cursor: pointer;
    font-size: 20px;
    transition: 0.2s;
    color: black;
    border: 3px solid #4c7a94;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    width: 200px;
    background-color: rgba(255, 255, 225, 0.5);
    font-weight: bold;
  }

  .waves-background {
    background-color: #274060;
  }

  .slide-show {
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-top: 55px;
  }

  .slide-container {
    float: right;
    width: 100%;
  }
  .grid-container {
    width: 95%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 10px;
  }
  .grid-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    height: 30px;
    width: 80%;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    align-items: center;
    margin: 10px;
    padding: 1rem;
  }
  .grid-item:hover {
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .about-us-column-2 > li {
    text-align: left;
  }
  .home-page-about {
    width: 95%;
    margin: auto;
    text-align: justify;
  }
  .home-page-become-an-agent {
    width: calc(100vw - 40px);
    height: 100%;
    background-color: rgb(240, 240, 240);

    margin-bottom: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;

    text-align: center;
  }
  .home-page-become-an-agent-picture {
    width: 95%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .select-state-select {
    padding: 1rem;
    margin: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
  }
  .select-state-select-card {
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
  }
  .grid-item-picture {
    width: 0px;
    pointer-events: none;
    opacity: 0;
  }
  .home-page-card-button {
    width: 0px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    transition: 0.2s;
    opacity: 0;
    pointer-events: none;
  }

  .app-icons {
    margin-top: 10px;
    display: inline-block;
  }
  .app-icons-download {
    height: 40px;
  }
  .continue-previous-quote-component-shown {
    position: fixed;
    height: 60%;
    max-height: 340px;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 85%;
    transition: 0.3s;
    z-index: 20;
  }
  .continue-previous-quote-component-hidden {
    position: fixed;
    height: 60%;
    background-color: white;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 10px;
    padding: 0rem 1rem 1rem 1rem;
    width: 85%;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    transform: scale(0.9);
  }

  .continue-button-confirm-login-home {
    position: absolute;
    bottom: -30px;
    display: flex;
    justify-content: center;
    left: 30%;
  }
  /* .continue-button-confirm-login-home button {
    position: absolute;
    border: none;
    background-color: #09203f;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    width: 75%;
    max-width: 175px;
    font-size: 16px;
  } */
  .about-us-picture {
    width: 100%;
    text-align: center;
  }

  .about-us-info {
    width: 90vw;
    margin: auto;
    margin-top: 3rem;
  }
  .our-mission-div {
    width: 80vw;
    margin: auto;
    margin-top: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 5px;
  }
  .video-carousel-container {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .video-carousel-container > h2 {
    padding-left: 15px;
  }
  /* waves */
}

.continue-previous-quote-dark-background-on {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 20;
  transition: 0.3s;
}
.continue-previous-quote-dark-background-off {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 1vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 10vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 30s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 14s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 17s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 20s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 27s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/

.mobileOnly {
  display: none;
}
.desktopOnly {
  display: inline-block;
}

@media (max-width: 768px) {
  .mobileOnly {
    display: inline-block;
    padding-right: 5px;
  }
  .desktopOnly {
    display: none;
  }
  .waves {
    height: 40px;
    min-height: 70px !important;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
  #STRETCH-ME {
    max-width: 650px !important;
    list-style: none;
  }
}

.get-a-quote-component-shown,
.otp-component-shown {
  position: fixed;
  z-index: 23;
  width: 300px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 10px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  text-align: center;
  transition: 0.3s;
}
.get-a-quote-component-hidden,
.otp-component-hidden {
  position: fixed;
  z-index: 0;
  width: 300px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  padding: 1rem;
  border-radius: 10px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  text-align: center;
  transform: scale(0.9);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
}

.grid-container {
  max-width: 1200px;
}

#MOBILE-ONLY > a > h2 {
  text-align: left !important;
}

.start-quote-button-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.banner-payment-options {
  font-size: 15px;
  cursor: pointer;
}

.home-page-left-div {
  text-align: center;
}

/* @media screen and (max-width: 800px) {
  .home-picture {
    display: inline-table !important;
  }
}
@media screen and (min-width: 600px) {
  .home-page-left-div {
    width: 100% !important;
    text-align: center;
  }
} */

@media screen and (min-width: 750px) {
  .mobileOnly {
    display: none;
  }
}
@media screen and (max-width: 750px) and (min-width: 525px) {
  .home-picture {
    height: 718px !important;
    display: grid !important;
  }
  .home-picture-div {
    display: block !important;
  }

  .renew-policy {
    display: block;
  }
}

.home-page-left-div {
  width: 100%;
}

.claims-button{
  margin-bottom: 6%;
}

.home-page-find-an-agent-picture{
  max-width: 380px;
}
@media only screen and (min-width: 600px) {
  .Not-Found-Page {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 1;
    transition: all 500ms ease-out;
  }
  .Not-Found-Page-not-rendered {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: all 500ms ease-in;
    transform: scale(0.9);
  }
  .Not-Found-Page-Div {
    width: 50%;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 600px) {
  .Not-Found-Page {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 1;
    transition: all 500ms;
  }
  .Not-Found-Page-not-rendered {
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: all 500ms;
    transform: scale(0.97);
  }
  .Not-Found-Page-Div {
    margin-top: 2rem;
    width: 90%;
  }
}

.accessibility {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.accessibility-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}

.accessibility-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}

.statement-container {
  margin: auto;
  max-width: 900px;
  margin-top: 3rem;
}

.site-requirements {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.site-requirements-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}

.site-map-div {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.component-to-display-div-site-req {
  width: 50%;
  display: flex;
  justify-content: center;
}
.site-requirements-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}

.sitemap {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.sitemap-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}

.sitemap-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}

.link-wrapper {
  margin-left: 60px;
}
.site-map-link {
  color: #01224f;
  text-decoration: underline;
  cursor: pointer;
}

.privacy {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.privacy-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}

.privacy-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}

.newUser {
  margin: auto;
  margin: 1rem;
  opacity: 1;
  transition: 0.3s;
}
.new-user-iframe {
  display: flex;
  justify-content: center;
  width: 90vw;
  margin: auto;
  margin-top: 2rem;
}
.newUser-not-rendered {
  margin: auto;
  margin: 1rem;
  opacity: 0;
  transition: 0.3s;
  transform: scale(0.97);
}
.newUser-box {
  padding: 1rem;
  background-color: #fbfbfb;
  max-width: 85vw;
  margin: auto;
  font-family: "abadi_mt_condensed_extra_boRg";
  border-top: 2px solid rgb(100, 100, 255);
  border-bottom: 1px solid rgb(160, 160, 160);
  color: rgb(50, 50, 150);
  margin-top: 3rem;
}
.create-account-div {
  width: 500px;
  margin-right: 3rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.get-a-quote-route-component {
  position: fixed;
  height: 100%;
  width: 100%;
  color: rgb(0, 0, 0);
  z-index: 50;
  top: 0;
  padding-top: 2rem;
  background:
        /* top, transparent black, faked with gradient */ linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    /* bottom, image */
      url(https://images.pexels.com/photos/8425044/pexels-photo-8425044.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: 0.7s;

  /* optional, center the image */
}
.get-a-quote-route-component.off {
  transform: scale(1.1);
  transition: 0.7s ease-out;
}

.get-a-quote-route-header {
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 50px;
  padding-top: 10%;
}
.get-a-quote-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
}
.get-a-quote-input-div {
  display: block;
  margin: auto;
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.5);
}
.get-a-quote-button-wrapper {
  margin-top: 1rem;
}
@media only screen and (min-width: 600px) {
  .get-a-quote-previous-quote-shown {
    bottom: 325px !important;
  }
}

@media only screen and (min-width: 600px) {
  .baa-banner {
    max-width: 700px;
    margin: 56px auto 135px auto;
    padding: 10px 10px 100px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    margin: auto;
    opacity: 1;
    transition: all 500ms;
  }
  .baa-banner-not-rendered {
    max-width: 700px;
    margin: 56px auto 135px auto;
    padding: 10px 10px 100px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    margin: 2rem;
    margin: auto;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .baa {
    font-size: 45px;
    border-bottom-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
  }

  .baa-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .txt-input-div {
    width: 60%;
    margin: auto;
    margin-top: 2rem;
  }
  .txt-input-select {
    padding: 1rem;
    font-size: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}
@media only screen and (max-width: 800px) {
.find-an-agent-div{
  display: inline-block !important;

}

}
@media only screen and (max-width: 600px) {
  .baa-banner {
    max-width: 80%;
    margin: 56px auto 135px auto;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    margin: auto;
    opacity: 1;
    transition: all 500ms;
  }
  .baa-banner-not-rendered {
    max-width: 80%;
    margin: 56px auto 135px auto;
    padding: 10px 10px 20px 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    margin: 2rem;
    margin: auto;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .baa {
    font-size: 30px;
    border-bottom-width: 1px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
  }

  .baa-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .txt-input-div {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
  .txt-input-select {
    padding: 1rem;
    font-size: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
  }
}
.locations-div{
  min-width: 275px;
  margin:1em;
  line-height: 18px;
}

.faa-img{
  width:250px;}

div.baa-banner.faa-banner > div > span{
  display: flex !important;
  
}

.findButton, .faaCallButton{
  background-color: #01224f;
  border-radius: 5px;
  border: 0;
  color: white;
  font-family: inherit;
  font-size: 1.17em;
  font-weight: bolder;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 17px;
  max-height: 55px;
  cursor: pointer;
  margin-top: 10px;
  /* margin-left: -20px; */
}

.faa-input-div {
    display: flex;
    /* margin-top: -20px !important; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.row1{
  height:30px;
  display: block;
}

.row2{
  height:65px;
  display: block;
}

.row3{
  height:20px;
  display: block;
}

.row4{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  display: block;
}

.top-recommended{
  position: relative;
  left: 64%;
  top: -37px;
  padding: 10px;
  background-color: green;
  color: white;
}
.top-empty{
  position: relative;
  left: 64%;
  top: -37px;
  padding: 10px;
  color: white;
}


.findButton:disabled{
  background-color: grey; cursor: not-allowed;
  background-color: #EBEBE4 !important;
  color: #C6C6C6;
  text-decoration: none;
}

.faa-message{
  top:85px;
}

.no-agents-found{
  margin:1em;
  padding:1em;
  font-weight: bold;
  color:red;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
