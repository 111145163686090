@media only screen and (min-width: 900px) {
  .contact-us-state {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .contact-us-state-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }

  .contact {
    margin: auto;
    opacity: 1;
    margin: 1rem;
    margin-top: 0;
    transition: all 500ms;
  }
  .contact-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }
  .contact-us-container {
    display: flex;
    margin-left: 10vw;
    width: 80%;

    margin-top: 3rem;
  }
  .state-div {
    display: flex;
    flex-wrap: wrap;
  }
  .request-help-parent {
    display: flex;
    justify-content: left;
    width: 80%;
    margin-left: 5%;
    margin-top: 2rem;
  }
  .request-help-about {
    font-size: 18px;
  }
  .request-help-clickable-header:hover {
    text-decoration: underline;
    transition: 0.2s;
    cursor: pointer;
  }
}
@media only screen and (max-width: 500px) {
  .need-assistance {
    margin-bottom: -25px !important;
    float: none !important;
    width: 90% !important;
  }
}
@media only screen and (max-width: 900px) {
  .need-assistance {
    margin-right: 0% !important;
    position: relative !important;
  }
  .contact-us-state {
    margin: auto;
    margin: 1rem;
    opacity: 1;
    transition: all 500ms;
  }
  .contact-us-state-not-rendered {
    margin: auto;
    margin: 1rem;
    opacity: 0;
    transition: all 500ms;
    transform: translateY(50px);
  }

  .contact-us-box {
    padding: 1rem;
    background-color: #fbfbfb;
    max-width: 85vw;
    margin: auto;
    font-family: "abadi_mt_condensed_extra_boRg";
    border-top: 2px solid rgb(100, 100, 255);
    border-bottom: 1px solid rgb(160, 160, 160);
    color: rgb(50, 50, 150);
    margin-top: 3rem;
    display: flex;
    align-items: center;
  }

  .contact {
    margin: auto;
    opacity: 1;
    margin: 1rem;
    margin-top: 0;
    transition: 0.3s;
    margin-bottom: 2rem;
  }
  .contact h4 {
    font-weight: bold;
    color: rgb(49, 49, 49);
  }
  .contact-not-rendered {
    margin: auto;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0.97);
  }
  .contact-us-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 3rem;
  }
  .state-div {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: 4rem;
  }
  .state-div div {
    width: 100%;
    margin: auto;
    margin-bottom: 1rem;
  }
  .navigation-mobile-closed-contact-us {
    position: absolute;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 25px;
    transition: 0.1s;
    overflow: hidden;
    z-index: 1;
  }
  .navigation-mobile-open-contact-us {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 80vw;
    margin: auto;
    left: 0;
    right: 0;
    height: 140px;
    transition: 0.3s;
    overflow: hidden;
    z-index: 10;
  }
  .request-help-parent {
    display: flex;
    justify-content: left;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
  .request-help-about {
    font-size: 18px;
  }
  .request-help-clickable-header:hover {
    text-decoration: underline;
    transition: 0.2s;
    cursor: pointer;
  }
}
.request-help-clickable-header {
  text-decoration: underline;
}

.MuiInputBase-input,
.MuiSelect-selectMenu {
  width: 175px;
}

.datepicker {
  max-width: 190px;
}

.need-assistance {
  float: right;
  position: absolute;
  margin-right: 6%;
  right: 0;
  margin-top: -25px !important;
}

.contact li {
  line-height: 25px !important;
}