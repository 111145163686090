.header {
  background-color: #09203f;
  margin: 0;
  padding: 10px;
  position: fixed;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: space-between;
}
.header-mobile-closed {
  background-color: #01224f;
  margin: 0;
  padding: 10px;
  position: fixed;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 60px;
  transition: 100ms;
  z-index: 5;
}
.header-mobile-open {
  background-color: #01224f;
  margin: 0;
  padding: 10px;
  position: fixed;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100%;
  transition: 300ms;
  z-index: 10;
}

.navigation-links-div-mobile {
  margin: auto;
  width: 80vw;
}

.login-button {
  margin-right: 30px;
}

.login-button button {
  background-color: #01224f;
  border-radius: 5px;
  border: 0;
  color: white;
  font-family: inherit;
  font-size: 1.17em;
  font-weight: bolder;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 13px;
  cursor: pointer;
}

.login-button button:hover {
  transition: 100ms;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: gray;
  padding: 13px;
}
.navigation-button-aboutUs-active,
.navigation-button-claims-active,
.navigation-button-become-agent-active,
.navigation-button-insurance-active,
.navigation-button-career-active,
.navigation-button-contact-active,
.navigation-button-leinholder-active,
.navigation-button-find-an-agent-active,
.navigation-button-login-active {
  color: white;
  text-decoration: none;
  margin: 0rem 10px 0rem 10px;
  padding: 0rem 5px 0rem 5px;
  transition: 100ms;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: 3px solid white;
  height: 30px;
  font-weight: normal;
}
.navigation-button-hidden {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}
.navigation-button-aboutUs-off,
.navigation-button-aboutUs-off,
.navigation-button-claims-off,
.navigation-button-become-agent-off,
.navigation-button-insurance-off,
.navigation-button-career-off,
.navigation-button-contact-off,
.navigation-button-find-an-agent-off,
.navigation-button-leinholder-off,
.navigation-button-login-off {
  color: white;
  text-decoration: none;
  margin: 0rem 10px 0rem 10px;
  padding: 0rem 5px 0rem 5px;
  transition: 100ms;
  text-align: center;
  display: flex;
  align-items: center;
  height: 30px;
  font-weight: normal;
}

.navigation-links {
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.navigation-button-aboutUs-off:hover,
.navigation-button-claims-off:hover,
.navigation-button-become-agent-off:hover,
.navigation-button-insurance-off:hover,
.navigation-button-career-off:hover,
.navigation-button-contact-off:hover,
.navigation-button-find-an-agent-off:hover,
.navigation-button-leinholder-off:hover,
.navigation-button-login-off:hover {
  border-bottom: 3px solid white;
  height: 30px;
}
.logo-header {
  height: 60px;
  margin: 0;
}

.login-box-opened {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 400px;
  min-width: 340px;
  height: 595px;
  margin: auto;
  transition: all 200ms;
  padding: 1rem;
  border-radius: 10px;
  display: list-item;
  /* overflow: hidden; */
}

.login-box-closed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 700px;
  height: 500px;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  transition: all 200ms;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.login-box-opened-small {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  height: 500px;
  margin: auto;
  transition: 200ms;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}
/* 
width: 100%;
  max-width: 650px;
  max-height: 500px;
  height: 100%; */

.login-box-closed-small {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 11;
  padding: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  height: 500px;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.Agents-Div {
  float: left;
  padding: 30px;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  text-align: center;
}

.agent-icon {
  width: auto;
  height: 175px;
}

.Customers-Div {
  float: left;
  padding: 30px;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  text-align: center;
}

.customer-icon {
  width: auto;
  height: 175px;
}

.close-login-menu-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  padding: 10px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 200ms;
}
.close-login-menu-icon:hover {
  padding: 10px;
  box-shadow: 0px 0px 0px 5px rgb(201, 201, 201);
  background-color: rgb(201, 201, 201);
  transition: 200ms;
}

.home-page-card-button,
.home-page-button {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 10px;
  transition: 200ms ease-in;
  cursor: pointer;
}
.home-page-card-button:hover,
.home-page-button:hover {
  transition: 200ms ease-in-out;
  background-color: #266f00;
}
.home-page-card-button-off,
.home-page-button-off {
  width: 150px;
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #266f00;
  padding: 10px;
  transition: all 200ms;
  opacity: 0.65;
  cursor: not-allowed;
}

.error-message-shown {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #a30000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: 300ms;
  opacity: 1;
  z-index: 100;
}
.error-message-hidden {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #a30000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: 300ms;
  -webkit-transform: translateY(-100px);
  transform: translateY(-200px);
  opacity: 0;
  color: rgb(0, 0, 0, 0);
  background-color: rgb(0, 0, 0, 0);
}
.message-shown {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #266f00;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 70%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: all 300ms ease-in-out;
  opacity: 1;
  z-index: 100;
}
.message-hidden {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #266f00;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 70%;
  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: 300ms ease-in-out;
  -webkit-transform: translateY(-200px);
  transform: translateY(-200px);
  opacity: 0;
}
.new-user,
.forgot-password {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.new-user:hover,
.forgot-password:hover {
  text-decoration: underline;
  color: #01224f;
}
.forgot-password-component-shown {
  position: absolute;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 80%;
  height: 225px;
  z-index: 21;
  border-radius: 10px;
  transition: all 200ms;
}
.forgot-password-component-hidden {
  position: absolute;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 80%;
  height: 190px;
  z-index: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: 10px;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 200ms;
  opacity: 0;
  display: none;
}

.forgot-password-background-on {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 20;
  transition: all 300ms;
  will-change: opacity;
}
.forgot-password-background-off {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms;
  will-change: opacity;
}
.confirm-login-message-component-open {
  /* width: 650px; */
  max-width: 500px;
  width: 90%;
  position: fixed;
  background-color: white;
  margin: auto;
  left: 0;
  right: 0;
  height: 300px;
  top: 0;
  bottom: 0;
  padding: 1rem;
  border-radius: 10px;
  z-index: 21;
  transition: all 300ms;
}
.confirm-login-message-component-closed {
  max-width: 500px;
  width: 90%;
  position: fixed;
  background-color: white;
  margin: auto;
  left: 0;
  right: 0;
  height: 300px;
  top: 0;
  bottom: 0;
  padding: 1rem;
  border-radius: 10px;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 300ms;
}
.continue-button-confirm-login {
  position: absolute;
  right: 50px;
  bottom: 20px;
}
.continue-button-confirm-login button {
  border: none;
  background-color: #09203f;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 200ms;
}
.continue-button-confirm-login button:hover {
  border: none;
  background-color: #144686;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 200ms;
}

.login-button {
  position: absolute;
  right: 10px;
  top: 15px;
}
/* ipad pro */
@media (min-width: 1100px) and (max-width: 500px) {
  .login-box-opened {
    /* max-width: 600px; */
    min-width: 400px !important;
  }
  .login-box-closed {
    max-width: 600px;
  }

  .login-button {
    position: absolute;
    right: 70px;
    top: 15px;
  }
}
/* #root
  > div
  > div:nth-child(3)
  > div
  > div.login-box-opened
  > div.Agents-Div
  > div {
  margin-top: 22px;
} */
.mobile {
  display: none !important;
}
.new-user-div {
  margin-top: 0.5rem !important;
}
.payment-Shortcut {
  margin-top: 10px;
}
/* phones */
@media (max-width: 1100px) {
  .login-button {
    right: 55px;
  }
}
@media (max-width: 600px) {

  .logo-header {
    height: 50px !important;
    margin: 0;
  }

  .header-mobile-closed,
  .header-mobile-open {
    padding: 10px 0px !important;
  }

  .payment-Shortcut {
    margin-top: 10px;
  }

  .new-user-div {
    margin-top: 0.5rem !important;
    margin-bottom: 0.25rem;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block !important;
    margin-top: 10px;
  }

  .login-box-opened > .Agents-Div button,
  .login-box-opened > .Customers-Div button {
    opacity: 1 !important;
    width: 80%;
    padding: 10px;
    max-width: 150px;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    color: white;
    margin: 5px;
    background-color: #266f00;
  }
  .login-box-opened {
    display: block;
    height: 615px;
    width: calc(95% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }

  .login-box-closed {
    display: block;
    height: 600px;
    width: calc(90% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }
  .login-box-opened-small {
    display: block;
    height: 350px;
    width: calc(95% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }
  .login-box-closed-small {
    display: block;
    height: 350px;
    width: calc(90% - 2rem);
    margin: 0;
    padding: 0rem;
    margin: auto;
  }

  .Agents-Div,
  .Customers-Div {
    float: none;
  }

  .Agents-Div h3,
  .Customers-Div h3 {
    font-size: 24px;
    padding: 10px;
  }

  .Customers-Div {
    padding-top: 0;
  }

  .login-button {
    position: absolute;
    right: 70px;
    top: 15px;
  }

  .close-login-menu-icon {
    position: absolute;
    top: 10px;
  }
  .confirm-login-message-component-open {
    max-width: 500px;
    width: 90%;
    position: fixed;
    background-color: white;
    margin: auto;
    left: 0;
    right: 0;
    height: 300px;
    top: 0;
    bottom: 0;
    padding: 1rem;
    border-radius: 10px;
    z-index: 21;
    transition: all 300ms;
  }
  .confirm-login-message-component-closed {
    max-width: 500px;
    width: 90%;
    position: fixed;
    background-color: white;
    margin: auto;
    left: 0;
    right: 0;
    height: 300px;
    top: 0;
    bottom: 0;
    padding: 1rem;
    border-radius: 10px;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transition: all 300ms;
  }
  .error-message-shown {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 10px;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    opacity: 1;
    z-index: 100;
    font-size: 14px;
  }
  .error-message-hidden {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #a30000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    -webkit-transform: translateY(-100px);
    transform: translateY(-200px);
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  .message-shown {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 10px;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    opacity: 1;
    z-index: 100;
    font-size: 14px;
  }
  .message-hidden {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #266f00;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 85%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    transition: all 300ms;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    font-size: 14px;
    opacity: 0;
    color: rgb(0, 0, 0, 0);
    background-color: rgb(0, 0, 0, 0);
  }
  /* .MuiFormControlLabel-root:first-child {
    margin-left: 0px !important;
    margin-right: 0px;
  } */

  .Customers-Div,
  .Agents-Div {
    padding-top: 0px !important;
  }
  .login-container {
    margin-top: 40px;
  }
}

.weather-message {
  color: #a30000;
  font-weight: bold;
}
.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  margin-bottom: 10px;
}

.Customers-Div,
.Agents-Div {
  width: 250px;
  padding-top: 10px;
  padding: 0px;
  height: 500px;
  border: 2px solid WHITE;
}

.Customers-Div img,
.Agents-Div img {
  border-radius: 50%;
  border: 5px solid #01224f;
  margin: 8%;
  height: 115px;
}

.Customers-Div img {
  margin-bottom: 2% !important;
}

.MuiFormGroup-root {
  display: block !important;
}

#SHOW-ME {
  display: block !important;
}
#HIDE-ME,
.HIDE-ME {
  display: none !important;
}
#FLEX-ME {
  display: flex !important;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 0px;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  padding: 3px;
  padding-right: 8px;
}
/* 
.MuiFormControlLabel-root:first-child {
  margin-left: 15px;
} */
@media (min-width: 500px) {
  .MARG-50 {
    margin-top: 50px;
  }
}

#STRETCH-ME {
  max-width: 750px !important;
  list-style: none;
}

.grid-item.popup {
  height: 200px !important;
  width: 235px !important;
  text-align: center;
}

.grid-item.popup > h2 {
  text-align: center !important;
}
.grid-item.popup:after {
  display: none;
}

.grid-item.popup > h2.blue-color:after,
.grid-item.popup > div > h2.blue-color:after {
  display: none;
}

.grid-item.popup > input[type="radio"] {
  border: none;
}

.SPOTTED,
.grid-item.popup > img {
  display: none !important;
}
#GRID-ME {
  display: flex !important;
  list-style: none;
}

.field-container#HIDE-ME {
  display: block !important;
}
@media (max-width: 600px) {
  .field-container#HIDE-ME {
    display: none !important;
  }
  #GRID-ME {
    display: grid !important;
    list-style: none;
  }
  #EXTEND-ME {
    margin-top: 55px !important;
  }
  .Agents-Div {
    margin-top: 55px !important;
  }
  .Customers-Div,
  .Agents-Div {
    height: 200px !important;

    margin: 10px auto;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgb(0 0 0 / 15%);
    width: 80%;
    text-align: center;
    cursor: pointer;
    /* transition: 0.3s; */
    align-items: center;
    display: list-item;
    padding: 1rem;
  }
  .Customers-Div > .field-container,
  .Agents-Div > .field-container {
    display: none;
  }
}

#EXTEND-ME {
  height: 465px !important;
  background: NONE !important;
  box-shadow: NONE !important;
}
#HIDE-ME\ EXTEND-ME {
  display: none !important;
}

.backButton {
  left: 7px !important;
  top: 7px;
}

.backButton > button {
  background: #f0f0f0;
  border: 1px solid;
}
.get-a-quote-footer {
  position: absolute;
  bottom: 5px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
.get-a-quote-footer ul {
  display: flex;
  padding: 0;
}
.get-a-quote-footer li {
  margin: 1rem;
}
.get-a-quote-copy-rigth {
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10px;
}
.get-a-quote-footer,
.get-a-quote-copy-rigth {
  display: none;
}
