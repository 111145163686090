.leinForm {
  display: flex;
  justify-content: center;
  width: 90vw;
  margin: auto;
}
.leinInput {
  float: left;
  width: 20%;
  height: 825px;
  margin-top: 25px;
}

.leinFieldset {
  border: 1px solid #ced4da;
  display: inline-block;
  background-color: white;
  margin-top: 25px;
  padding: 26px;
}

.search {
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 25%;
}

.leinFieldset fieldset {
  border: none;
  font-size: 18 px;
  width: fit-content;
}

.leinFieldset input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.leinFieldset button {
  padding: 5px 20px 5px 20px;
  color: #ffffff;
  border: 1px solid #266f00 !important;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 35%;
  font-size: 13px !important;
  text-shadow: 1px 1px 0px #222;
  background-color: #076415;
}

.logout {
  float: right;
  padding: 10px;
}

.leinDetails h1 {
  padding: 10px;
}

.half-left {
  float: left;
  width: 25%;
  padding-left: 5%;
}

.middle-left {
  float: left;
  width: 25%;
}

.third-left {
  float: left;
}
